<template>
  <div class="body">
    <p align="center" class="title">車両利用申請点検</p>
    <v-row>
      <v-col align="right" class="button">
        <v-btn
          @click="makeCSVDownload"
          class="mr-5"
          color="orange white--text"
          :disabled="isLoading"
        >
          CSV出力
        </v-btn>
        <v-btn
          @click.once="back"
          :color="
            approvalFlagFID8582 != '' ||
            approvalFlagFID8583 != '' ||
            approvalFlagFID8616 != ''
              ? 'red white--text'
              : ''
          "
        >
          {{
            approvalFlagFID8582 != "" ||
            approvalFlagFID8583 != "" ||
            approvalFlagFID8616 != ""
              ? "未保存で戻る"
              : "戻る"
          }}
        </v-btn>
      </v-col>
    </v-row>
    <div v-if="isLoading" align="center">
      <v-progress-circular
        :size="250"
        color="primary"
        indeterminate
        align="center"
      ></v-progress-circular>
    </div>
    <div v-else>
      <v-form ref="form2" v-model="valid">
        <v-row class="ml-2 mr-1 mt-2">
          <v-col cols="2">
            <v-text-field
              label="車両番号"
              v-model="keyword8588"
              background-color="white"
              id="FID8588"
              class="searchForm"
              outlined
              clearable
              hide-details="auto"
            >
            </v-text-field>
          </v-col>
          <v-col cols="3" sm="3" md="3" lg="3">
            <v-menu
              v-model="keyword8731.show"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on }"
                ><v-text-field
                  label="利用月"
                  v-model="keyword8731.date"
                  background-color="white"
                  id="FID8731"
                  class="searchForm"
                  outlined
                  v-on="on"
                  readonly
                  clearable
                  hide-details="auto"
                >
                </v-text-field>
              </template>
              <v-date-picker
                v-model="keyword8731.date"
                type="month"
                no-title
                scrollable
                locale="ja-jp"
                :day-format="(date) => new Date(date).getDate()"
                @input="keyword8731.show = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6" sm="6" md="2" lg="2">
            <v-autocomplete
              label="ステータス"
              v-model="keyword8589"
              :items="selectBox8589"
              id="FID8589"
              class="selectBox8589"
              outlined
              :clearable="
                (this.$store.state.params.FID7765.value.includes('給油確認者') ||
                this.$store.state.params.FID7766.value.includes('車両確認者')) &&
                this.$store.state.params.FID8748.value.includes('車両確認者(日別)')
              "
              no-data-text="データが見つかりません"
              :rules="[]"
              hide-details="auto"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row class="ml-2 mr-1 mt-2">
          <v-col cols="6" sm="6" md="2" lg="2">
            <v-text-field
              label="事業本部"
              :rules="[]"
              v-model="keyword1"
              outlined
              no-data-text="データが見つかりません"
              clearable
              hide-details="auto"
              disabled
            >
            </v-text-field>
          </v-col>
          <v-col cols="6" sm="6" md="2" lg="2">
            <v-text-field
              label="部署名"
              :rules="[rules.required]"
              v-model="keyword2"
              outlined
              no-data-text="データが見つかりません"
              disabled
            >
            </v-text-field>
          </v-col>
          <v-col cols="6" sm="6" md="3" lg="3">
            <v-autocomplete
              label="グループ"
              :rules="[]"
              v-model="keyword3"
              :items="selectBox3"
              id="FID8322"
              class="selectBox8322"
              outlined
              no-data-text="データが見つかりません"
              clearable
              item-text="label"
              item-value="value"
              hide-details="auto"
              @change="setSelectBox4"
              :disabled="disabledSelectbox_3_4"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="6" sm="6" md="3" lg="3">
            <v-autocomplete
              label="店舗"
              v-model="keyword4"
              :items="selectBox4"
              id="FID8322"
              class="selectBox8322"
              outlined
              no-data-text="データが見つかりません"
              clearable
              item-text="label"
              item-value="value"
              hide-details="auto"
              :disabled="disabledSelectbox_3_4"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="0">
            <v-btn x-large @click="search()" color="primary white--text">
              検索
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <v-form ref="form" v-model="valid">
        <v-row class="ml-2 mr-1 mt-2">
          <v-col cols="">
            <v-data-table
              :headers="headers"
              :items="filterReports"
              :item-key="reports.ID"
              :page="
                $store.state.pageNum == undefined ? 1 : $store.state.pageNum
              "
              :sort-by="sortBy"
              :sort-desc="sortDesc"
              :header-props="{
                'sort-by-text': '並び順',
              }"
              :footer-props="{
                'items-per-page-text': '行/ページ:',
              }"
              no-data-text="データが存在しません"
              :items-per-page="displayResults"
              @update:page="updatePageNum"
              @update:sort-by="updateSortBy"
              @update:sort-desc="updateSortDesc"
              :custom-sort="customSort"
            >
              <template v-slot:no-results>
                <span>データが存在しません</span>
              </template>

              <!-- ---->
              <template v-slot:item="{ item, index }">
                <template
                  v-if="
                    typeof item.Recodes.FID8026.subReports != 'undefined' &&
                    item.Recodes.FID8026.subReports != null &&
                    item.Recodes.FID8026.subReports.length > 0
                  "
                >
                  <template
                    v-for="(sub0, i0) in item.Recodes.FID8026.subReports"
                  >
                    <tr
                      :class="index % 2 == 0 ? 'trWhite' : 'trBlue'"
                      :key="'sub-' + index + '-' + i0"
                    >
                      <td
                        v-if="
                          typeof item.Recodes.FID8021 != 'undefined' && i0 == 0
                        "
                      >
                        <v-autocomplete
                          label="ステータス"
                          v-model="item.Recodes.FID8021.value"
                          :disabled="
                            item.Recodes.FID8021.disabled ||
                            allDisabled ||
                            item.DeleteFlag ||
                            allDisabled ||
                            item.DeleteFlag
                          "
                          id="FID8021"
                          class="selectBox8021"
                          no-item-text="データが見つかりません"
                          clearable
                          :items="selectBoxFID8021"
                          hide-details="auto"
                        >
                        </v-autocomplete>
                      </td>
                      <td v-else></td>
                      <td
                        v-if="
                          typeof item.Recodes.FID8023 != 'undefined' && i0 == 0
                        "
                      >
                        <v-text-field
                          label="車両番号"
                          v-model="item.Recodes.FID8023.value"
                          :disabled="
                            allDisabled ||
                            item.DeleteFlag ||
                            allDisabled ||
                            item.DeleteFlag
                          "
                          :rules="[rules.required]"
                          id="FID8023"
                          class="textfield8023"
                          hide-details="auto"
                        >
                        </v-text-field>
                      </td>
                      <td v-else></td>
                      <!-- 利用目的 -->
                      <td v-if="typeof item.Recodes.FID8014 != 'undefined'">
                        <v-text-field
                          label="利用目的"
                          v-model="item.Recodes.FID8014.value"
                          :disabled="
                            allDisabled ||
                            item.DeleteFlag ||
                            allDisabled ||
                            item.DeleteFlag
                          "
                          :rules="[rules.required]"
                          id="FID8014"
                          class="textfield8014"
                          hide-details="auto"
                        >
                        </v-text-field>
                      </td>
                      <td v-else></td>
                      <td
                        v-if="
                          typeof item.Recodes.FID8017 != 'undefined' && i0 == 0
                        "
                      >
                        <v-menu
                          v-model="item.Recodes.FID8017.modal"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              label="利用開始日"
                              v-model="item.Recodes.FID8017.value"
                              :disabled="
                                allDisabled ||
                                item.DeleteFlag ||
                                allDisabled ||
                                item.DeleteFlag
                              "
                              :rules="[rules.required]"
                              id="FID8017"
                              class="inputTime8017"
                              append-icon="mdi-calendar"
                              v-on="on"
                              readonly
                              hide-details="auto"
                            >
                            </v-text-field> </template
                          ><v-date-picker
                            v-model="item.Recodes.FID8017.value"
                            no-title
                            scrollable
                            locale="ja-jp"
                            :day-format="(date) => new Date(date).getDate()"
                            @input="item.Recodes.FID8017.modal = false"
                            :max="item.Recodes.FID8018.value"
                          ></v-date-picker>
                        </v-menu>
                      </td>
                      <td v-else></td>
                      <td
                        v-if="
                          typeof item.Recodes.FID8018 != 'undefined' && i0 == 0
                        "
                      >
                        <v-menu
                          v-model="item.Recodes.FID8018.modal"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              label="利用終了日"
                              v-model="item.Recodes.FID8018.value"
                              :disabled="
                                allDisabled ||
                                item.DeleteFlag ||
                                allDisabled ||
                                item.DeleteFlag
                              "
                              :rules="[rules.required]"
                              id="FID8018"
                              class="inputTime8018"
                              append-icon="mdi-calendar"
                              v-on="on"
                              readonly
                              hide-details="auto"
                            >
                            </v-text-field> </template
                          ><v-date-picker
                            v-model="item.Recodes.FID8018.value"
                            no-title
                            scrollable
                            locale="ja-jp"
                            :day-format="(date) => new Date(date).getDate()"
                            @input="item.Recodes.FID8018.modal = false"
                            :min="item.Recodes.FID8017.value"
                          ></v-date-picker>
                        </v-menu>
                      </td>
                      <td v-else></td>
                      <!-- 運転者 -->
                      <td v-if="typeof item.Recodes.FID8016 != 'undefined'">
                        <!--  v-model="item.Recodes.FID8016.value"  -->
                        <v-text-field
                          label="運転者"
                          :value="GetName(item.Recodes.FID8016.value)"
                          :disabled="
                            allDisabled ||
                            item.DeleteFlag ||
                            allDisabled ||
                            item.DeleteFlag
                          "
                          :rules="[rules.required]"
                          id="FID8016"
                          class="textfield8016"
                          hide-details="auto"
                        >
                        </v-text-field>
                      </td>
                      <td v-else></td>
                      <td
                        v-if="
                          typeof item.Recodes.FID8019 != 'undefined' && i0 == 0
                        "
                      >
                        <v-text-field
                          label="行き先・貸出し先"
                          v-model="item.Recodes.FID8019.value"
                          :disabled="
                            item.Recodes.FID8019.disabled ||
                            allDisabled ||
                            item.DeleteFlag ||
                            allDisabled ||
                            item.DeleteFlag
                          "
                          :rules="[rules.required]"
                          id="FID8019"
                          class="textfield8019"
                          hide-details="auto"
                        >
                        </v-text-field>
                      </td>
                      <td v-else></td>
                      <td
                        v-if="
                          typeof item.Recodes.FID8616 != 'undefined' && i0 == 0
                        "
                      >
                        <v-text-field
                          label="日次承認"
                          v-model="item.Recodes.FID8616.value"
                          disabled
                          id="FID8616"
                          class="textfield8616"
                          hide-details="auto"
                        >
                        </v-text-field>
                      </td>
                      <td v-else></td>
                      <td
                        v-if="
                          typeof item.Recodes.FID8583 != 'undefined' && i0 == 0
                        "
                      >
                        <v-text-field
                          label="月次承認"
                          v-model="item.Recodes.FID8583.value"
                          :disabled="
                            item.Recodes.FID8583.disabled ||
                            allDisabled ||
                            item.DeleteFlag ||
                            allDisabled ||
                            item.DeleteFlag
                          "
                          id="FID8583"
                          class="textfield8583"
                          hide-details="auto"
                        >
                        </v-text-field>
                      </td>
                      <td v-else></td>
                      <!-- 前回距離 -->
                      <td v-if="typeof sub0.Recodes.FID8255 != 'undefined'">
                        <v-text-field
                          label="前回距離"
                          v-model="sub0.Recodes.FID8255.value"
                          :disabled="
                            sub0.Recodes.FID8255.disabled ||
                            allDisabled ||
                            item.DeleteFlag ||
                            sub0.DeleteFlag
                          "
                          id="FID8255"
                          class="textfield8255"
                          hide-details="auto"
                        >
                        </v-text-field>
                      </td>
                      <td v-else></td>
                      <!-- 現在距離 -->
                      <td v-if="typeof sub0.Recodes.FID8256 != 'undefined'">
                        <v-text-field
                          label="現在距離"
                          v-model="sub0.Recodes.FID8256.value"
                          :disabled="
                            sub0.Recodes.FID8256.disabled ||
                            allDisabled ||
                            item.DeleteFlag ||
                            sub0.DeleteFlag
                          "
                          id="FID8256"
                          class="textfield8256"
                          hide-details="auto"
                        >
                        </v-text-field>
                      </td>
                      <td v-else></td>
                      <!-- 走行距離 -->
                      <td v-if="typeof sub0.Recodes.FID8270 != 'undefined'">
                        <v-text-field
                          label="走行距離"
                          v-model="sub0.Recodes.FID8270.value"
                          :disabled="
                            sub0.Recodes.FID8270.disabled ||
                            allDisabled ||
                            item.DeleteFlag ||
                            sub0.DeleteFlag
                          "
                          id="FID8270"
                          class="textfield8270"
                          hide-details="auto"
                        >
                        </v-text-field>
                      </td>
                      <td v-else></td>
                      <!-- 開始時間 -->
                      <td v-if="typeof sub0.Recodes.FID8032 != 'undefined'">
                        <v-text-field
                          label="開始時間"
                          v-model="sub0.Recodes.FID8032.value"
                          :disabled="
                            sub0.Recodes.FID8032.disabled ||
                            allDisabled ||
                            item.DeleteFlag ||
                            sub0.DeleteFlag
                          "
                          id="FID8032"
                          class="textfield8032"
                          hide-details="auto"
                        >
                        </v-text-field>
                      </td>
                      <td v-else></td>
                      <!-- 終了時間 -->
                      <td v-if="typeof sub0.Recodes.FID8033 != 'undefined'">
                        <v-text-field
                          label="終了時間"
                          v-model="sub0.Recodes.FID8033.value"
                          :disabled="
                            sub0.Recodes.FID8033.disabled ||
                            allDisabled ||
                            item.DeleteFlag ||
                            sub0.DeleteFlag
                          "
                          id="FID8033"
                          class="textfield8033"
                          hide-details="auto"
                        >
                        </v-text-field>
                      </td>
                      <td v-else></td>
                      <!-- ETC利用有無 -->
                      <td v-if="typeof sub0.Recodes.FID8034 != 'undefined'">
                        <div class="pa-0">
                          <v-radio-group
                            v-model="sub0.Recodes.FID8034.value"
                            :disabled="
                              allDisabled || item.DeleteFlag || sub0.DeleteFlag
                            "
                            id="FID8034"
                            class="radioGroup8034 pb-1 ma-0"
                            style="border-radius: 4px; border-color: #9f9f9f"
                            row
                            hide-details="auto"
                          >
                            <v-radio
                              label="有り"
                              value="有り"
                              class="mt-1"
                            ></v-radio>
                            <v-radio
                              label="無し"
                              value="無し"
                              class="mt-1"
                            ></v-radio>
                          </v-radio-group>
                        </div>
                      </td>
                      <td v-else></td>
                      <td v-if="typeof sub0.Recodes.FID8035 != 'undefined'">
                        <v-dialog
                          v-model="sub0.Recodes.FID8035.modal"
                          :max-width="resizeWidth"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs">
                              <v-text-field
                                label="燃料(L)"
                                v-model="sub0.Recodes.FID8035.value"
                                :disabled="
                                  allDisabled ||
                                  item.DeleteFlag ||
                                  sub0.DeleteFlag
                                "
                                :rules="[rules.smallNum]"
                                id="FID8035"
                                class="textfield8035"
                                v-bind="attrs"
                                v-on="on"
                                readonly
                                hide-details="auto"
                              >
                              </v-text-field></div></template
                          ><input-Number
                            v-if="sub0.Recodes.FID8035.modal"
                            title="燃料(L)"
                            :value="sub0.Recodes.FID8035.value"
                            :rules="[rules.smallNum]"
                            v-on:ok="
                              (sub0.Recodes.FID8035.modal = false),
                                $set(sub0.Recodes.FID8035, 'value', $event)
                            "
                            type="calculator"
                          ></input-Number>
                        </v-dialog>
                      </td>
                      <td v-else></td>
                      <td v-if="typeof sub0.Recodes.FID8036 != 'undefined'">
                        <v-dialog
                          v-model="sub0.Recodes.FID8036.modal"
                          :max-width="resizeWidth"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs">
                              <v-text-field
                                label="オイル(L)"
                                v-model="sub0.Recodes.FID8036.value"
                                :disabled="
                                  allDisabled ||
                                  item.DeleteFlag ||
                                  sub0.DeleteFlag
                                "
                                :rules="[rules.smallNum]"
                                id="FID8036"
                                class="textfield8036"
                                v-bind="attrs"
                                v-on="on"
                                readonly
                                hide-details="auto"
                              >
                              </v-text-field></div></template
                          ><input-Number
                            v-if="sub0.Recodes.FID8036.modal"
                            title="オイル(L)"
                            :value="sub0.Recodes.FID8036.value"
                            :rules="[rules.smallNum]"
                            v-on:ok="
                              (sub0.Recodes.FID8036.modal = false),
                                $set(sub0.Recodes.FID8036, 'value', $event)
                            "
                            type="calculator"
                          ></input-Number>
                        </v-dialog>
                      </td>
                      <td v-else></td>
                      <td v-if="typeof sub0.Recodes.FID8582 != 'undefined'">
                        <v-text-field
                          label="オイル点検"
                          v-model="sub0.Recodes.FID8582.value"
                          :disabled="
                            sub0.Recodes.FID8582.disabled ||
                            allDisabled ||
                            item.DeleteFlag ||
                            sub0.DeleteFlag
                          "
                          id="FID8582"
                          class="textfield8582"
                          hide-details="auto"
                        >
                        </v-text-field>
                      </td>
                      <td v-else></td>
                      <!-- 異常個所 及び 交換部品 -->
                      <td v-if="typeof sub0.Recodes.FID8037 != 'undefined'">
                        <v-text-field
                          label="異常個所 及び 交換部品"
                          v-model="sub0.Recodes.FID8037.value"
                          :disabled="
                            sub0.Recodes.FID8037.disabled ||
                            allDisabled ||
                            item.DeleteFlag ||
                            sub0.DeleteFlag
                          "
                          id="FID8037"
                          class="textfield8037"
                          hide-details="auto"
                        >
                        </v-text-field>
                      </td>
                      <td v-else></td>
                      <!-- 修正理由を記入 -->
                      <td v-if="typeof sub0.Recodes.FID10000 != 'undefined'">
                        <v-text-field
                          label="修正理由"
                          v-model="sub0.Recodes.FID10000.value"
                          :disabled="
                            sub0.Recodes.FID10000.disabled ||
                            allDisabled ||
                            item.DeleteFlag ||
                            sub0.DeleteFlag
                          "
                          id="FID10000"
                          class="textfield10000"
                          hide-details="auto"
                        >
                        </v-text-field>
                      </td>
                      <td v-else></td>
                      <!-- 酒気帯び運転確認:業務前 Recodes.FID8025.subReports -->
                      <template
                        v-if="item.Recodes.FID8014.value === '業務利用'"
                      >
                        <!-- 確認方法 -->
                        <td
                          v-if="
                            typeof item.Recodes.FID8025.subReports[0].Recodes
                              .FID8029 != 'undefined'
                          "
                        >
                          <v-text-field
                            label="確認方法：業務前"
                            v-model="
                              item.Recodes.FID8025.subReports[0].Recodes.FID8029
                                .value
                            "
                            :disabled="
                              item.Recodes.FID8025.subReports[0].Recodes.FID8029
                                .disabled ||
                              allDisabled ||
                              item.DeleteFlag ||
                              item.Recodes.FID8025.subReports[0].DeleteFlag
                            "
                            id="FID8029"
                            class="textfield8029"
                            hide-details="auto"
                          >
                          </v-text-field>
                        </td>
                        <td v-else></td>
                        <!-- 確認者氏名 -->
                        <td
                          v-if="
                            typeof item.Recodes.FID8025.subReports[0].Recodes
                              .FID8047 != 'undefined'
                          "
                        >
                          <v-text-field
                            label="確認者氏名：業務前"
                            v-model="
                              item.Recodes.FID8025.subReports[0].Recodes.FID8047
                                .value
                            "
                            :disabled="
                              item.Recodes.FID8025.subReports[0].Recodes.FID8047
                                .disabled ||
                              allDisabled ||
                              item.DeleteFlag ||
                              item.Recodes.FID8025.subReports[0].DeleteFlag
                            "
                            id="FID8047"
                            class="textfield8047"
                            hide-details="auto"
                          >
                          </v-text-field>
                        </td>
                        <td v-else></td>
                        <!-- 酒気帯び -->
                        <td
                          v-if="
                            typeof item.Recodes.FID8025.subReports[0].Recodes
                              .FID8253 != 'undefined' &&
                            item.Recodes.FID8025.subReports[0].Recodes.FID8253
                              .value != null
                          "
                        >
                          <v-text-field
                            label="酒気帯び：業務前"
                            value="無"
                            :disabled="
                              item.Recodes.FID8025.subReports[0].Recodes.FID8253
                                .disabled ||
                              allDisabled ||
                              item.DeleteFlag ||
                              item.Recodes.FID8025.subReports[0].DeleteFlag
                            "
                            id="FID8253"
                            class="textfield8253"
                            hide-details="auto"
                          >
                          </v-text-field>
                        </td>
                        <td
                          v-else-if="
                            typeof item.Recodes.FID8025.subReports[0].Recodes
                              .FID8039 != 'undefined' &&
                            item.Recodes.FID8025.subReports[0].Recodes.FID8039
                              .value != null
                          "
                        >
                          <v-text-field
                            label="酒気帯び：業務前"
                            value="有"
                            :disabled="
                              item.Recodes.FID8025.subReports[0].Recodes.FID8039
                                .disabled ||
                              allDisabled ||
                              item.DeleteFlag ||
                              item.Recodes.FID8025.subReports[0].DeleteFlag
                            "
                            id="FID8039"
                            class="textfield8039"
                            hide-details="auto"
                          >
                          </v-text-field>
                        </td>
                        <td v-else></td>
                        <!-- 確認時間 -->
                        <td
                          v-if="
                            typeof item.Recodes.FID8025.subReports[0].Recodes
                              .FID8040 != 'undefined'
                          "
                        >
                          <v-text-field
                            label="確認時間：業務前"
                            v-model="
                              item.Recodes.FID8025.subReports[0].Recodes.FID8040
                                .value
                            "
                            :disabled="
                              item.Recodes.FID8025.subReports[0].Recodes.FID8040
                                .disabled ||
                              allDisabled ||
                              item.DeleteFlag ||
                              item.Recodes.FID8040.subReports[0].DeleteFlag
                            "
                            id="FID8040"
                            class="textfield8040"
                            hide-details="auto"
                          >
                          </v-text-field>
                        </td>
                        <td v-else></td>
                      </template>
                      <template v-else>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </template>
                      <!-- 酒気帯び運転確認:業務後 Recodes.FID8027.subReports -->
                      <template
                        v-if="item.Recodes.FID8014.value === '業務利用'"
                      >
                        <!-- 確認方法 -->
                        <td
                          v-if="
                            typeof item.Recodes.FID8027.subReports[0].Recodes
                              .FID8042 != 'undefined'
                          "
                        >
                          <v-text-field
                            label="確認方法：業務後"
                            v-model="
                              item.Recodes.FID8027.subReports[0].Recodes.FID8042
                                .value
                            "
                            :disabled="
                              item.Recodes.FID8027.subReports[0].Recodes.FID8042
                                .disabled ||
                              allDisabled ||
                              item.DeleteFlag ||
                              item.Recodes.FID8027.subReports[0].DeleteFlag
                            "
                            id="FID8042"
                            class="textfield8042"
                            hide-details="auto"
                          >
                          </v-text-field>
                        </td>
                        <td v-else></td>
                        <!-- 確認者氏名 -->
                        <td
                          v-if="
                            typeof item.Recodes.FID8027.subReports[0].Recodes
                              .FID8049 != 'undefined'
                          "
                        >
                          <v-text-field
                            label="確認者氏名：業務後"
                            v-model="
                              item.Recodes.FID8027.subReports[0].Recodes.FID8049
                                .value
                            "
                            :disabled="
                              item.Recodes.FID8027.subReports[0].Recodes.FID8049
                                .disabled ||
                              allDisabled ||
                              item.DeleteFlag ||
                              item.Recodes.FID8027.subReports[0].DeleteFlag
                            "
                            id="FID8049"
                            class="textfield8049"
                            hide-details="auto"
                          >
                          </v-text-field>
                        </td>
                        <td v-else></td>
                        <!-- 酒気帯び -->
                        <td
                          v-if="
                            typeof item.Recodes.FID8025.subReports[0].Recodes
                              .FID8253 != 'undefined' &&
                            item.Recodes.FID8025.subReports[0].Recodes.FID8253
                              .value != null
                          "
                        >
                          <v-text-field
                            label="酒気帯び：業務後"
                            value="無"
                            :disabled="
                              item.Recodes.FID8025.subReports[0].Recodes.FID8253
                                .disabled ||
                              allDisabled ||
                              item.DeleteFlag ||
                              item.Recodes.FID8025.subReports[0].DeleteFlag
                            "
                            id="FID8253"
                            class="textfield8253"
                            hide-details="auto"
                          >
                          </v-text-field>
                        </td>
                        <td v-else></td>
                        <!-- 確認時間 -->
                        <td
                          v-if="
                            typeof item.Recodes.FID8027.subReports[0].Recodes
                              .FID8045 != 'undefined'
                          "
                        >
                          <v-text-field
                            label="確認時間：業務後"
                            v-model="
                              item.Recodes.FID8027.subReports[0].Recodes.FID8045
                                .value
                            "
                            :disabled="
                              item.Recodes.FID8027.subReports[0].Recodes.FID8045
                                .disabled ||
                              allDisabled ||
                              item.DeleteFlag ||
                              item.Recodes.FID8027.subReports[0].DeleteFlag
                            "
                            id="FID8045"
                            class="textfield8045"
                            hide-details="auto"
                          >
                          </v-text-field>
                        </td>
                        <td v-else></td>
                      </template>
                      <template v-else>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </template>
                    </tr>
                  </template>
                </template>
                <template v-else>
                  <tr :class="index % 2 == 0 ? 'trWhite' : 'trBlue'">
                    <td v-if="typeof item.Recodes.FID8023 != 'undefined'">
                      <v-text-field
                        label="車両番号"
                        v-model="item.Recodes.FID8023.value"
                        :disabled="allDisabled || item.DeleteFlag"
                        :rules="[rules.required]"
                        id="FID8023"
                        class="textfield8023"
                        hide-details="auto"
                      >
                      </v-text-field>
                    </td>
                    <td v-else></td>
                    <td v-if="typeof item.Recodes.FID8021 != 'undefined'">
                      <v-autocomplete
                        label="ステータス"
                        v-model="item.Recodes.FID8021.value"
                        :disabled="
                          item.Recodes.FID8021.disabled ||
                          allDisabled ||
                          item.DeleteFlag
                        "
                        id="FID8021"
                        class="selectBox8021"
                        no-item-text="データが見つかりません"
                        clearable
                        :items="selectBoxFID8021"
                        hide-details="auto"
                      >
                      </v-autocomplete>
                    </td>
                    <td v-else></td>
                    <td v-if="typeof item.Recodes.FID8017 != 'undefined'">
                      <v-menu
                        v-model="item.Recodes.FID8017.modal"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            label="利用開始日"
                            v-model="item.Recodes.FID8017.value"
                            :disabled="allDisabled || item.DeleteFlag"
                            :rules="[rules.required]"
                            id="FID8017"
                            class="inputTime8017"
                            append-icon="mdi-calendar"
                            v-on="on"
                            readonly
                            hide-details="auto"
                          >
                          </v-text-field> </template
                        ><v-date-picker
                          v-model="item.Recodes.FID8017.value"
                          no-title
                          scrollable
                          locale="ja-jp"
                          :day-format="(date) => new Date(date).getDate()"
                          @input="item.Recodes.FID8017.modal = false"
                          :max="item.Recodes.FID8018.value"
                        ></v-date-picker>
                      </v-menu>
                    </td>
                    <td v-else></td>
                    <td v-if="typeof item.Recodes.FID8018 != 'undefined'">
                      <v-menu
                        v-model="item.Recodes.FID8018.modal"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            label="利用終了日"
                            v-model="item.Recodes.FID8018.value"
                            :disabled="allDisabled || item.DeleteFlag"
                            :rules="[rules.required]"
                            id="FID8018"
                            class="inputTime8018"
                            append-icon="mdi-calendar"
                            v-on="on"
                            readonly
                            hide-details="auto"
                          >
                          </v-text-field> </template
                        ><v-date-picker
                          v-model="item.Recodes.FID8018.value"
                          no-title
                          scrollable
                          locale="ja-jp"
                          :day-format="(date) => new Date(date).getDate()"
                          @input="item.Recodes.FID8018.modal = false"
                          :min="item.Recodes.FID8017.value"
                        ></v-date-picker>
                      </v-menu>
                    </td>
                    <td v-else></td>
                    <td v-if="typeof item.Recodes.FID8019 != 'undefined'">
                      <v-text-field
                        label="行き先・貸出し先"
                        v-model="item.Recodes.FID8019.value"
                        :disabled="
                          item.Recodes.FID8019.disabled ||
                          allDisabled ||
                          item.DeleteFlag
                        "
                        :rules="[rules.required]"
                        id="FID8019"
                        class="textfield8019"
                        hide-details="auto"
                      >
                      </v-text-field>
                    </td>
                    <td v-else></td>
                    <td v-if="typeof item.Recodes.FID8616 != 'undefined'">
                      <v-text-field
                        label="日次承認"
                        v-model="item.Recodes.FID8616.value"
                        disabled
                        id="FID8616"
                        class="textfield8616"
                        hide-details="auto"
                      >
                      </v-text-field>
                    </td>
                    <td v-else></td>
                    <td v-if="typeof item.Recodes.FID8583 != 'undefined'">
                      <v-text-field
                        label="月次承認"
                        v-model="item.Recodes.FID8583.value"
                        :disabled="
                          item.Recodes.FID8583.disabled ||
                          allDisabled ||
                          item.DeleteFlag
                        "
                        id="FID8583"
                        class="textfield8583"
                        hide-details="auto"
                      >
                      </v-text-field>
                    </td>
                    <td v-else></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </template>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="mb-2" cols="8"> </v-col>
          <v-col class="mb-2" cols="1">
            <v-btn
              @click="
                (approvalFlagFID8616 = approvalFlagFID8616 == '' ? 'true' : ''),
                  approvalFID8616()
              "
              :color="
                !approvalFlagFID8616 ? 'blue white--text' : 'red white--text'
              "
              class="text-caption ml-3 mb-2"
              large
              :disabled="disabledFID8616 || approvalFlagFID8583 != ''"
            >
              {{ !approvalFlagFID8616 ? "日次承認" : "キャンセル" }}
            </v-btn>
          </v-col>
          <v-col class="mb-2" cols="1">
            <v-btn
              @click="
                (approvalFlagFID8583 = approvalFlagFID8583 == '' ? 'true' : ''),
                  approvalFID8583()
              "
              :color="
                !approvalFlagFID8583 ? 'blue white--text' : 'red white--text'
              "
              class="text-caption ml-3 mb-2"
              large
              :disabled="disabledFID8581 || approvalFlagFID8616 != '' "
            >
              {{ !approvalFlagFID8583 ? "月次承認" : "キャンセル" }}
            </v-btn>
          </v-col>
          <v-col class="mb-2" cols="1">
            <v-btn
              @click="
                (approvalFlagFID8582 = approvalFlagFID8582 == '' ? 'true' : ''),
                  approvalFID8582()
              "
              :color="
                !approvalFlagFID8582 ? 'blue white--text' : 'red white--text'
              "
              class="text-caption"
              large
              :disabled="disabledFID8584"
            >
              {{ !approvalFlagFID8582 ? "点検" : "キャンセル" }}
            </v-btn>
          </v-col>
          <v-col cols="1">
            <v-btn @click="send" color="primary" large> 保存 </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </div>
</template>

<script>
import store from "../store/vuex.js";
import { RepositoryFactory } from "../api/RepositoryFactory";
const api = RepositoryFactory.get("seisei_back");
import {
  carInspection1,
  carInspection2,
  carInspection3,
} from "./specialMethod";
import inputNumber from "../views/inputNumber";
const eneosCar = RepositoryFactory.get("eneosCar");
import { applicationReport } from "./csvExport/applicationReport";
export default {
  name: "ReportIndex",
  components: {
    inputNumber,
  },
  data() {
    return {
      valid: true,
      initEscape: false,
      isLoading: false,
      fullPage: false,
      displayResults: 15, //5,10,15のいずれか
      height: 100,
      width: 250,
      path: "",
      sortBy: 'Recodes.FID8023.value',
      sortDesc: null,
      //
      PageID: 1160,
      reports: [],
      filterReports: [],
      rules: {
        requiredArray: (value) => (value.length > 0 ? true : "必須項目です"),
        fullWidth: (value) => {
          return /^[^ -~｡-ﾟ]*$/.test(value) || "全角のみ入力可能です";
        },
        fileSize: (value) => {
          return (
            value == null ||
            value.size < 15000000 ||
            "ファイルサイズを15MB未満にしてください"
          );
        },
        required: (value) => !!value || "必須項目です",
        smallNum: (value) => {
          return /^(\d*.?\d+)*$/.test(value) || "数値のみ入力可能です。";
        },
      },
      buttonShow: true,
      data: {
        PageID: 1160,
        ReportID: null,
        Recodes: {
          FID8023: { value: "", formType: 1, disabled: false },
          FID8021: { value: "申請前", formType: 2, disabled: true },
          FID8016: { value: "", formType: 2, disabled: false },
          FID8017: { value: "", formType: 3, disabled: false, modal: false },
          FID8018: { value: "", formType: 3, disabled: false, modal: false },
          FID8019: { value: "", formType: 1, disabled: false },
          FID8583: { value: "", formType: 1, disabled: true },
          FID8026: {
            value: "PID1162",
            formType: 17,
            disabled: false,
            subReports: [],
          },
        },
      },

      keyword8589: "",
      selectBox8589: [],
      keyword9574: this.$store.state.params.FID7776
        ? this.$store.state.params.FID7776.value
        : "",
      keyword8588: "",
      keyword8731: {
        date: 
          new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
          )
            .toISOString()
            .match(/^\d{4}-\d{2}/g)
            .pop(),
        show: false,
      },

      allDisabled: true,
      selectBoxFID8021: [
        "申請前",
        "業務前",
        "利用中",
        "業務後",
        "提出済",
        "承認済",
      ],
      disabledFID8581: true,
      approvalFlagFID8583: "",
      disabledFID8584: true,
      approvalFlagFID8582: "",
      disabledFID8616: true,
      approvalFlagFID8616: "",
      disabledSelectbox_3_4: false,
      subReport8026: {
        PageID: 1162,
        ReportID: null,
        Recodes: {
          FID8030: { value: "", formType: 16, disabled: false },
          FID8031: { value: "", formType: 16, disabled: true },
          FID8032: { value: "", formType: 1, disabled: true },
          FID8033: { value: "", formType: 1, disabled: true },
          FID8034: { value: "", formType: 7, disabled: false },
          FID8035: { value: "0", formType: 1, disabled: false, modal: false },
          FID8036: { value: "0", formType: 1, disabled: false, modal: false },
          FID8037: { value: "", formType: 1, disabled: false },
          FID8255: { value: "", formType: 1, disabled: false, modal: false },
          FID8256: { value: "", formType: 1, disabled: false, modal: false },
          FID8270: { value: "", formType: 1, disabled: true, modal: false },
          FID8582: { value: "", formType: 1, disabled: true },
          FID10000: { value: "", formType: 1, disabled: false },
        },
        DeleteFlag: false,
      },
      ruleMap: {
        FID8023: ["required"],
        FID8017: ["required"],
        FID8018: ["required"],
        FID8019: ["required"],
        FID8035: ["smallNum"],
        FID8036: ["smallNum"],
      },
      //部署検索
      //keyword1: "",
      keyword2: "",
      keyword1Code: "",
      keyword2Code: "",
      keyword3: "",
      keyword4: "",
      selectBox3: [],
      selectBox4: [],
      ALLDepartment: "",
      //
      userCodeToNameMap: {},
    };
  },
  async created() {
    await this.getInit();
  },
  methods: {
    async getInit() {
      //タイトルをデフォルトに戻す
      if (typeof process.env.VUE_APP_BASE_TITLE != "undefined") {
        document.title = process.env.VUE_APP_BASE_TITLE;
      }
      //初期処理
      this.isLoading = true;
      this.getKeyWord(this.$store.state.keyWord);
      this.path = this.$route.path;
      //
      await store.commit("setDepartmentAll");
      await this.initKeyword();
      this.initSelectBox8589();
      //データ取得
      if (JSON.stringify(this.$store.state.reports) != "{}") {
        this.reports = this.$store.state.reports;
      } else {
        let profile;
        [, profile] = await Promise.all([this.search(), api.GetRole()]);
        store.commit("setRole", profile.Role);
        //要素がない場合の回避処理
        this.reports.map((report, index) => {
          for (const [key, Recode] of Object.entries(this.data.Recodes)) {
            if (typeof report.Recodes[key] == "undefined") {
              this.reports[index].Recodes[key] = JSON.parse(
                JSON.stringify(Recode)
              );
            }
          }
        });
      }
      //月次承認済は表示しない
      this.getFilterReports();
      //
      if (this.$store.state.tableSort.sortBy != null) {
        this.sortBy = this.$store.state.tableSort.sortBy;
        this.sortDesc = this.$store.state.tableSort.sortDesc;
      }
      //データ取得処理

      //init処理
      this.initValues8023();
      // await this.initCheckFID8581();
      // await this.initCheckFID8584();
      // await this.initCheckFID8616();
      this.reports = this.reports.reverse();      
      //
      this.isLoading = false;
    },
    getDateTime() {
      let date = new Date();
      let years = date.getFullYear().toString();
      let month = (date.getMonth() + 1).toString().padStart(2, "0");
      let days = date.getDate().toString().padStart(2, "0");
      let hours = date.getHours().toString().padStart(2, "0");
      let minutes = date.getMinutes().toString().padStart(2, "0");
      let seconds = date.getSeconds().toString().padStart(2, "0");
      return (
        years +
        "-" +
        month +
        "-" +
        days +
        " " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds
      );
    },
    initSelectBox8589() {
      if (store.state.params.FID8748.value.includes("車両確認者(日別)")) {
        this.selectBox8589.push("提出済");
        this.keyword8589 = "提出済";
      }
      if (
        store.state.params.FID7765.value.includes("給油確認者") ||
        store.state.params.FID7766.value.includes("車両確認者")
      ) {
        this.selectBox8589.push("日次承認済");
        this.keyword8589 = "日次承認済";
      }
      if (
        (store.state.params.FID7765.value.includes("給油確認者") ||
        store.state.params.FID7766.value.includes("車両確認者")) &&
        store.state.params.FID8748.value.includes("車両確認者(日別)")
      ) {
        this.keyword8589 = "";
      }
      this.selectBox8589.push("月次承認済");
    },
    approvalFID8583() {
      this.reports.map((repo) => {
        if (typeof repo.Recodes.FID8583.approvalFlag == "undefined") {
          repo.Recodes.FID8583.approvalFlag = "true";
        }
        //空の値のみ一括で入力する処理
        if (
          this.approvalFlagFID8583 == "true" &&
          repo.Recodes.FID8583.value == "" &&
          repo.Recodes.FID8583.approvalFlag &&
          repo.Recodes.FID8021.value == "承認済"
        ) {
          repo.Recodes.FID8583.value = this.getDateTime();

          repo.Recodes.FID8583.approvalFlag = true;
        } else if (
          (this.approvalFlagFID8583 == "" ||
            this.approvalFlagFID8583 == null) &&
          repo.Recodes.FID8583.approvalFlag
        ) {
          repo.Recodes.FID8583.value = "";
        } else {
          repo.Recodes.FID8583.approvalFlag = false;
        }
      });
    },
    approvalFID8616() {
      this.reports.map((repo) => {
        if (typeof repo.Recodes.FID8616.approvalFlag == "undefined") {
          repo.Recodes.FID8616.approvalFlag = "true";
        }
        //空の値のみ一括で入力する処理
        if (
          this.approvalFlagFID8616 == "true" &&
          repo.Recodes.FID8616.value == "" &&
          repo.Recodes.FID8616.approvalFlag
        ) {
          repo.Recodes.FID8616.value = this.getDateTime();
          repo.Recodes.FID8616.approvalFlag = true;
          //ステータスも変更
          repo.Recodes.FID8021.value = "承認済";
        } else if (
          (this.approvalFlagFID8616 == "" ||
            this.approvalFlagFID8616 == null) &&
          repo.Recodes.FID8616.approvalFlag
        ) {
          repo.Recodes.FID8616.value = "";
          repo.Recodes.FID8021.value = "提出済";
        } else {
          repo.Recodes.FID8616.approvalFlag = false;
        }
      });
    },
    approvalFID8582() {
      this.reports.map((repo) => {
        if (
          typeof repo.Recodes.FID8026 != "undefined" &&
          repo.Recodes.FID8026.subReports != null &&
          repo.Recodes.FID8026.subReports.length > 0
        ) {
          repo.Recodes.FID8026.subReports.map((sub) => {
            if (typeof sub.Recodes.FID8582.approvalFlag == "undefined") {
              sub.Recodes.FID8582.approvalFlag = "true";
            }
            //空の値のみ一括で入力する処理
            if (
              this.approvalFlagFID8582 == "true" &&
              sub.Recodes.FID8582.value == "" &&
              sub.Recodes.FID8582.approvalFlag &&
              repo.Recodes.FID8021.value == "承認済"
            ) {
              sub.Recodes.FID8582.value = "済";
              sub.Recodes.FID8582.approvalFlag = true;
            } else if (
              (this.approvalFlagFID8582 == "" ||
                this.approvalFlagFID8582 == null) &&
              sub.Recodes.FID8582.approvalFlag
            ) {
              sub.Recodes.FID8582.value = "";
            } else {
              sub.Recodes.FID8582.approvalFlag = false;
            }
          });
        }
      });
    },
    initValues8023() {
      if (
        this.data.Recodes.FID8023.value == "" &&
        typeof localStorage.getItem("kakanaiValueFID7996") != "undefined" &&
        localStorage.getItem("kakanaiValueFID7996") != null
      ) {
        this.data.Recodes.FID8023.value = localStorage.getItem(
          "kakanaiValueFID7996"
        );
      } else if (
        this.data.Recodes.FID8023.value == "" &&
        typeof localStorage.getItem("kakanaiValueFID8007") != "undefined" &&
        localStorage.getItem("kakanaiValueFID8007") != null
      ) {
        this.data.Recodes.FID8023.value = localStorage.getItem(
          "kakanaiValueFID8007"
        );
      }
    },
    // async initCheckFID8581() {
    //   this.disabledFID8581 = true; //初期値
    //   if (await carInspection1()) {
    //     this.disabledFID8581 = false;
    //   }
    // },
    // async initCheckFID8584() {
    //   this.disabledFID8584 = true; //初期値
    //   if (await carInspection2()) {
    //     this.disabledFID8584 = false;
    //   }
    // },
    // async initCheckFID8616() {
    //   this.disabledFID8616 = true; //初期値
    //   if (await carInspection3()) {
    //     this.disabledFID8616 = false;
    //   }
    // },
    getKeyWord(data) {
      if (data.PageID == this.PageID) {
        data.search.forEach((data) => {
          if (data.form_id == 8021) {
            this.keyword8589 = data.value;
          }
          if (data.form_id == 9572) {
            this.keyword9574 = data.value;
          }
          if (data.form_id == 8023) {
            this.keyword8588 = data.value;
          }
          //if (data.form_id == 8015) {
          //  this.keyword8731.date = data.value.split(",");
          //}
        });
      }
    },
    getSearchWord() {
      let data = {
        PageID: this.PageID,
        orderBy: "",
        search: [],
      };

      if (!this.keyword8589) {
        data.search.push({ form_id: 8021, value: "提出済,承認済", option: "commaValuesAnyMatch", });
      } else if (this.keyword8589 == "月次承認済" || this.keyword8589 == "日次承認済") {
        data.search.push({ form_id: 8021, value: "承認済" });
      } else if (this.keyword8589 != "") {
        data.search.push({ form_id: 8021, value: this.keyword8589 });
      }
      if (this.keyword8588 != "") {
        data.search.push({ form_id: 8023, value: this.keyword8588 });
      }
      //部署情報の検索
      if (this.keyword4 != "" && this.keyword4 != null) {
        data.search.push({
          form_id: 9572,
          value: this.keyword4,
          option: "match",
        });
      } else if (this.keyword3 != "" && this.keyword3 != null) {
        data.search.push({
          form_id: 9572,
          value: this.keyword3,
          option: "match",
        });
      } else {
        data.search.push({
          form_id: 9572,
          value: this.ALLDepartment,
          option: "commaValuesAnyMatch",
        });
      }
      //
      if (this.keyword8731.date != null && this.keyword8731.date != '') {
        // 月初と月末を初期化
        let startDate, endDate;

        // 月初を取得
        const startOfMonth = new Date(this.keyword8731.date);
        startOfMonth.setDate(1);

        // 月末を取得
        const endOfMonth = new Date(this.keyword8731.date);
        endOfMonth.setMonth(endOfMonth.getMonth() + 1)
        endOfMonth.setDate(0);

        startDate = startOfMonth.toISOString().split("T")[0];
        endDate = endOfMonth.toISOString().split("T")[0];

        // 月初から月末を検索
        const date = `${startDate},${endDate}`;
        data.search.push({ form_id: 8017, value: date, option: "dateOnly" });
      }
      this.keyword8731.show = false;

      return data;
    },
    async search() {
      if (this.initEscape == false) {
        this.initEscape = true;
        this.isLoading = false;
        await this.$nextTick();
      }
      const ck = await this.checkValidation2();
      if (!ck && !this.isLoading) {
        return;
      }
      //初期処理
      this.isLoading = true;
      //データ追加
      let data = await this.getSearchWord();
      //api
      const reports = await api.ReportSearch(data);

      if (reports != null) {
        this.reports = reports;
        //月次承認済は表示しない
        this.getFilterReports();
        //
        store.commit("setreports", reports);
        this.approvalFlagFID8582 = "";
        this.approvalFlagFID8583 = "";
        this.approvalFlagFID8616 = "";
        //日次承認済,またはステータスが空欄（初期値）で検索した場合のみ承認
        if (this.keyword8589 == "日次承認済" ||  !this.keyword8589) {
          if (carInspection1()) {
            this.disabledFID8581 = false;
          }
          if (carInspection2()) {
            this.disabledFID8584 = false;
          }
        } else {
          this.disabledFID8581 = true;
          this.disabledFID8584 = true;
        }
        //提出済,またはステータスが空欄（初期値）で検索した場合のみ承認
        if (this.keyword8589 == "提出済" || !this.keyword8589) {
          if (carInspection3()) {
            this.disabledFID8616 = false;
          }
        } else {
          this.disabledFID8616 = true;
        }
        // 給油確認者が月次承認済で検索した場合のみ点検
        if (this.keyword8589 == "月次承認済" && carInspection2()) {
          this.disabledFID8584 = false;
        }
      }
      store.commit("setkeyWord", data);
      //社員番号からユーザ名のリストを取得
      let requests = {
        IDs: [],
      };
      reports.map((repo) => {
        requests.IDs.push(repo.Recodes.FID8016.value);
      });
      requests.IDs = [...new Set(requests.IDs)];
      let response = await eneosCar.getUserCodeToNameAll(requests);
      this.userCodeToNameMap = response;
      //      
      this.isLoading = false;
    },
    async initKeyword() {
      if (Object.keys(this.$store.state.organization).length < 2) {
        await store.commit("setDepartmentAll");
      }
      //
      let response = await eneosCar.getOrganizationChart();
      this.keyword2 = response.label;
      this.ALLDepartment += response.value + ",";
      this.selectBox3 = response.group;

      // 権限が日次承認者のみの場合はグループ、店舗の選択を無効化
      // 自身のグループ、店舗で絞り込み
      if (this.selectBox3.length > 0 && carInspection3() && !carInspection1()) {
        this.disabledSelectbox_3_4 = true
        this.setGroupAndShop()
      } else {
      //それ以外の権限者のグループの初期値を設定
        let own_group = this.selectBox3.find((g) => 
          g.value == this.$store.state.params.FID7776.value
        );
        if (own_group) {
            this.keyword3 = own_group.value;
        } else {
            // 日次承認、月次承認の権限があり、店舗に所属している場合を考慮
            let shops = this.selectBox3.flatMap(box => box.shop != null ? box.shop: []);
            let own_shop = shops.find((s) => 
                s.value == this.$store.state.params.FID7776.value
            );
            if (own_shop) {
                let shop_group = this.selectBox3.find((g) => 
                    g.shop && g.shop.some(shop =>shop.value === own_shop.value)
                );
                this.keyword3 = shop_group.value;
            }
        }
        this.setSelectBox4()
      }
      
      //リストの作成
      for (let i = 0; i < response.group.length; i++) {
        this.ALLDepartment += response.group[i].value + ",";
        //
        if (response.group[i].shop != null) {
          for (let j = 0; j < response.group[i].shop.length; j++) {
            this.ALLDepartment += response.group[i].shop[j].value + ",";
          }
        }
      }
      //末尾のカンマを削除
      this.ALLDepartment = this.ALLDepartment.slice(0, -1);
    },
    setSelectBox4() {
      //対象を探す
      if (this.keyword3 == null) {
        this.selectBox4 = [];
        return;
      }
      for (let i = 0; i < this.selectBox3.length; i++) {
        if (this.selectBox3[i].value == this.keyword3) {
          this.selectBox4 = this.selectBox3[i].shop;
          this.keyword4 = "";
        }
      }
    },
    back() {
      store.commit("clearkeyWord");
      store.commit("clearreports");
      store.commit("clearPageNum");
      store.commit("clearTableSort");
      //
      if (this.$route.path == this.path) {
        history.back();
      } else {
        alert("読み込みエラー:ホームに戻ります");
        this.$router.push("/", () => {});
      }
      //history.back();
    },
    //reports用のチェック
    checkValidation: async function () {
      const checkReport = function (rules, ruleMap, report) {
        let flag = true;
        //通常のデータ内で実行するとfor分岐が地獄を見るので無し
        for (const [formID, Recode] of Object.entries(report.Recodes)) {
          if (typeof ruleMap[formID] != "undefined" && !report.DeleteFlag) {
            let ruleArr = ruleMap[formID];
            ruleArr.map((key) => {
              if (key != "") {
                const r = rules[key];
                let result = r(Recode.value);
                if (result != true) {
                  flag = false;
                }
              }
            });
          } else if (
            !report.DeleteFlag &&
            Recode.formType == 17 &&
            typeof Recode.subReports != "undefined" &&
            Recode.subReports != null
          ) {
            let subFlag = true;
            Recode.subReports.map((sub) => {
              const res = checkReport(rules, ruleMap, sub);
              if (subFlag) {
                subFlag = res;
              }
            });
            if (!subFlag) {
              flag = false;
            }
          }
        }
        return flag;
      };
      //デフォルト機能による検索は行うが判定は別
      let check = true;
      await this.$refs.form.validate();
      this.reports.map((report) => {
        let flag = checkReport(this.rules, this.ruleMap, report);
        if (check) {
          check = flag;
        }
      });
      return check;
    },
    //検索用のバリデーションチェック
    checkValidation2: async function () {
      let form = this.$refs.form2;
      if (typeof form == "undefined") {
        return false;
      }
      let check = form.validate();
      if (!check) {
        const firstError = form.inputs.find((input) => input.hasError);
        this.$vuetify.goTo(firstError, { offset: 100 });
        return check;
      }
      return check;
    },
    setReportID: function (report, pageIDs, index, delReports) {
      report.PageID = pageIDs[index]; //ページIDの追加
      //サブレポートを含む場合の再帰処理
      for (const [FID, Recode] of Object.entries(report.Recodes)) {
        if (Recode.formType == 17) {
          let subReports = [];
          if (Recode.subReports == null) {
            Recode.subReports = [];
          }
          if (typeof pageIDs[index + 1] != "undefined") {
            Recode.subReports.map((sub, i) => {
              const res = this.setReportID(
                report.Recodes[FID].subReports[i],
                pageIDs,
                index + 1,
                delReports
              );
              if (res != null && !report.DeleteFlag) {
                subReports.push(res);
              } else if (res != null && report.DeleteFlag) {
                delReports.push(res);
              }
            });
          } else {
            Recode.subReports = [];
          }
          report.Recodes[FID].subReports = subReports;
        }
      }
      //削除処理
      if (!report.DeleteFlag) {
        return report;
      } else if (report.ReportID != null) {
        delReports.push(report);
      }
      return null;
    },
    //自身のグループと店舗をセット
    setGroupAndShop() {
      let own_group = this.selectBox3.find((g) => 
        g.value == this.$store.state.params.FID7776.value
      );
      if (own_group) {
        this.keyword3 = own_group.value;
      } else {
        // 店舗を取得してから逆算式にグループを取得
        let shops = this.selectBox3.flatMap(box => box.shop != null ? box.shop: []);
        let own_shop = shops.find((s) => 
          s.value == this.$store.state.params.FID7776.value
        );
        if (own_shop) {
          let shop_group = this.selectBox3.find((g) => 
            g.shop && g.shop.some(shop =>shop.value === own_shop.value)
          );
          this.keyword3 = shop_group.value;
          this.setSelectBox4()
          this.keyword4 = own_shop.value;
        }
      }
    },
    async send() {
      //バリデーションチェック
      const isCorrectValue = await this.checkValidation();
      if (isCorrectValue == false) return;
      this.isLoading = true;
      //
      let reports = [];
      let delReports = [];
      let pageIDs = [1160, 1162];
      //
      for (let i = 0; i < this.reports.length; i++) {
        const repo = this.setReportID(this.reports[i], pageIDs, 0, delReports);
        if (repo != null) {
          reports.push(repo);
        }
      }
      const result = await api.RegReports(reports);
      if (
        result == undefined ||
        (result.result != "success" && result.result != "Success")
      ) {
        alert("データが登録できませんでした。");
      } else {
        alert("登録完了");
      }
      if (delReports.length > 0) {
        try {
          await api.DelReports(delReports);
        } catch (e) {
          alert("削除失敗");
        }
      }
      this.isLoading = false;
      //history.back();
      this.resetData();
    },
    getFilterReports() {
      this.filterReports = this.reports.filter(report => {
        const value = report.Recodes.FID8583.value;
        return this.keyword8589 == "月次承認済" ? value : value == '';
      });
    },
    getValue(v) {
      if (typeof v == "undefined") {
        return "";
      }
      return v.value;
    },
    downloadCSV(csv, filename) {
      //CSV出力部分
      let blob = new Blob([csv], { type: "text/csv" });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    },
    updatePageNum(value) {
      store.commit("setPageNum", value);
    },
    updateSortBy(value) {
      store.commit("setTableSortBy", value);
    },
    updateSortDesc(value) {
      store.commit("setTableSortDesc", value);
    },
    customSort(items, index, isDesc) {
      //デフォルト
      if (index.length == 0 || items.length == 0) {
        return items;
      }
      const regex = /[^0-9]/g;
      const order = isDesc[0] ? 1 : -1;
      const pos = parseInt(index[0].replace(regex, ""));
      items.sort((a, b) => {
        //アイコンソートは特殊処理
        if (index == "target") {
          return 0;
        }
        //
        return this.sort(a, b, order, pos);
      });
      //
      return items;
    },
    sort(a, b, order, pos) {
      if (
        typeof a.Recodes["FID" + pos] == "undefined" ||
        typeof b.Recodes["FID" + pos] == "undefined"
      )
        return -1;
      if (a.Recodes["FID" + pos].value == b.Recodes["FID" + pos].value)
        return 0;
      if (a.Recodes["FID" + pos].value === "") return 1;
      if (b.Recodes["FID" + pos].value === "") return -1;
      if (
        !isNaN(a.Recodes["FID" + pos].value) &&
        !isNaN(b.Recodes["FID" + pos].value)
      ) {
        return Number(a.Recodes["FID" + pos].value) ==
          Number(b.Recodes["FID" + pos].value)
          ? 0
          : Number(a.Recodes["FID" + pos].value) >
            Number(b.Recodes["FID" + pos].value)
          ? -1 * order
          : order;
      }
      return (
        a.Recodes["FID" + pos].value.localeCompare(
          b.Recodes["FID" + pos].value,
          "jp"
        ) *
        -1 *
        order
      );
    },
    dateSort(dateArr) {
      const date1 = new Date(dateArr[0].replace(/-/g, "/"));
      const date2 = new Date(dateArr[1].replace(/-/g, "/"));
      if (date2 - date1 > 0) {
        return dateArr;
      } else {
        return [dateArr[1], dateArr[0]];
      }
    },
    delIndex(item) {
      this.isLoading = true;
      item.DeleteFlag = !item.DeleteFlag;
      this.isLoading = false;
    },
    async makeCSVDownload() {
      let name = "車両利用申請一覧";
      console.log("this.keyword8731", this.keyword8731);
      if (this.keyword8731.date != null && this.keyword8731.date != '') {
        name +=
          "[" + this.keyword8731.date + "]";
      } 
      await store.commit("setDepartmentAll");
      applicationReport(this.reports, name);
    },
    GetName(value) {
      if (typeof this.userCodeToNameMap[value] == "undefined") {
        return value;
      }
      return this.userCodeToNameMap[value];
    },
    async resetData() {
      //車両番号初期化
      this.keyword8588 = "";
      //日付の初期化
      this.keyword8731.date = 
        new Date(new Date().getFullYear(),new Date().getMonth())
        .toISOString()
        .match(/^\d{4}-\d{2}/g)
        .pop();
        
      //店舗の初期化
      this.keyword4 = "";
      store.commit("clearkeyWord");
      store.commit("clearreports");
      store.commit("clearTableSort");
      await this.getInit();
    }
  },
  watch: {},
  computed: {
    headers() {
      return [
        {
          text: "ステータス",
          value: "Recodes.FID8021.value",
          sortable: true,
          width: "135px",
        },
        {
          text: "車両番号",
          value: "Recodes.FID8023.value",
          align: "start",
          sortable: true,
          width: "150px",
        },
        {
          text: "利用目的",
          value: "Recodes.FID8014.value",
          align: "start",
          sortable: true,
          width: "130px",
        },
        {
          text: "利用開始日",
          value: "Recodes.FID8017.value",
          sortable: true,
          width: "145px",
        },
        {
          text: "利用終了日",
          value: "Recodes.FID8018.value",
          sortable: true,
          width: "145px",
        },
        {
          text: "運転者",
          value: "Recodes.FID8016.value",
          sortable: true,
          width: "135px",
        },
        {
          text: "行き先・貸出し先",
          value: "Recodes.FID8019.value",
          sortable: true,
          width: "250px",
        },
        {
          text: "日次承認",
          value: "Recodes.FID8616.value",
          sortable: true,
          width: "180px",
        },
        {
          text: "月次承認",
          value: "Recodes.FID8583.value",
          sortable: true,
          width: "180px",
        },
        {
          text: "前回距離",
          value: "Recodes.FID8255.value",
          sortable: true,
          width: "160px",
        },
        {
          text: "現在距離",
          value: "Recodes.FID8256.value",
          sortable: true,
          width: "160px",
        },
        {
          text: "走行距離",
          value: "Recodes.FID8270.value",
          sortable: true,
          width: "160px",
        },
        {
          text: "開始時間",
          value: "Recodes.FID8032.value",
          sortable: true,
          width: "180px",
        },
        {
          text: "終了時間",
          value: "Recodes.FID8033.value",
          sortable: true,
          width: "180px",
        },
        {
          text: "ETC利用",
          value: "Recodes.FID8034.value",
          sortable: true,
          width: "200px",
        },
        {
          text: "燃料(L)",
          value: "Recodes.FID8035.value",
          sortable: true,
          width: "100px",
        },
        {
          text: "オイル(L)",
          value: "Recodes.FID8036.value",
          sortable: true,
          width: "110px",
        },
        {
          text: "オイル点検",
          value: "Recodes.FID8582.value",
          sortable: true,
          width: "120px",
        },
        {
          text: "異常個所 及び 交換部品",
          value: "Recodes.FID8037.value",
          sortable: true,
          width: "180px",
        },
        {
          text: "修正理由を記入",
          value: "Recodes.FID10000.value",
          sortable: true,
          width: "180px",
        },
        {
          text: "確認方法：業務前",
          value: "Recodes.FID8029.value",
          sortable: true,
          width: "190px",
        },
        {
          text: "確認者氏名：業務前",
          value: "Recodes.FID8047.value",
          sortable: true,
          width: "200px",
        },
        {
          text: "酒気帯び：業務前",
          value: "Recodes.FID8253.value",
          sortable: true,
          width: "160px",
        },
        {
          text: "確認時間：業務前",
          value: "Recodes.FID8040.value",
          sortable: true,
          width: "180px",
        },
        {
          text: "確認方法：業務後",
          value: "Recodes.FID8029.value",
          sortable: true,
          width: "190px",
        },
        {
          text: "確認者氏名：業務後",
          value: "Recodes.FID8047.value",
          sortable: true,
          width: "200px",
        },
        {
          text: "酒気帯び：業務後",
          value: "Recodes.FID8253.value",
          sortable: true,
          width: "160px",
        },
        {
          text: "確認時間：業務後",
          value: "Recodes.FID8045.value",
          sortable: true,
          width: "180px",
        },
      ];
    },
    resizeWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "270px";
        case "sm":
          return "500px";
        case "md":
          return "600px";
        case "lg":
          return "500px";
        case "xl":
          return "500px";
        default:
          return "500px";
      }
    },
    keyword1() {
      return typeof store.state.organization[
        store.state.params.FID7767.value
      ] == "undefined"
        ? "読み込み中"
        : store.state.organization[store.state.params.FID7767.value];
    },
  },
};
</script>
