<template>
  <div class="home">
    <p>マイカー新規登録・設定</p>
    <v-row>
      <v-col align="right"><v-btn @click.once="back">戻る</v-btn></v-col>
    </v-row>
    <v-container cols="12">
      <div v-if="isLoading" align="center">
        <v-progress-circular
          :size="250"
          color="primary"
          indeterminate
          align="center"
        ></v-progress-circular>
      </div>
      <div v-else>
        <v-form ref="form" @submit.prevent>
          <v-row>
            <v-col cols="0">
              <v-text-field
                label="社員コード"
                v-model="data.Recodes.FID7989.value"
                disabled
                background-color="white"
                id="FID7989"
                class="userInfo7989"
                outlined
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
            <v-col cols="0">
              <v-text-field
                label="氏名"
                v-model="data.Recodes.FID7990.value"
                disabled
                background-color="white"
                id="FID7990"
                class="userInfo7990"
                outlined
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="0">
              <!-- v-model="data.Recodes.FID7991.value" -->
              <v-text-field
                label="事務所・事業所"
                :value="$store.state.organization[data.Recodes.FID7991.value]"
                disabled
                background-color="white"
                id="FID7991"
                class="userInfo7991"
                outlined
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
            <v-col cols="0">
              <!--  v-model="data.Recodes.FID7992.value" -->
              <v-text-field
                label="部署"
                :value="$store.state.organization[data.Recodes.FID7992.value]"
                disabled
                background-color="white"
                id="FID7992"
                class="userInfo7992"
                outlined
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
            <v-col cols="0">
              <v-text-field
                label="従業員区分"
                v-model="data.Recodes.FID7993.value"
                disabled
                background-color="white"
                id="FID7993"
                class="userInfo7993"
                outlined
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row
            v-if="
              $store.state.role == 1 &&
              data.ReportID != null &&
              this.data.Recodes.FID8504.value != '申請前'
            "
          >
            <v-col cols="0">
              <p>↓↓↓管理者はこちらから編集してください↓↓↓</p>
            </v-col>
          </v-row>
          <v-row
            v-if="
              $store.state.role == 1 &&
              data.ReportID != null &&
              this.data.Recodes.FID8504.value != '申請前'
            "
          >
            <v-col cols="0">
              <v-autocomplete
                label="事務所・事業所"
                v-model="data.Recodes.FID7991.value"
                background-color="white"
                class="selectBox8321"
                outlined
                no-data-text="データが見つかりません"
                clearable
                :items="allDivisinos"
                item-text="label"
                item-value="value"
                hide-details="auto"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="0">
              <v-autocomplete
                label="部署"
                v-model="data.Recodes.FID7992.value"
                background-color="white"
                class="selectBox8321"
                outlined
                no-data-text="データが見つかりません"
                clearable
                :items="allDepartments"
                item-text="label"
                item-value="value"
                hide-details="auto"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="0">
              <v-select
                label="従業員区分"
                v-model="data.Recodes.FID7993.value"
                id="FID7777"
                class="selectBox7777"
                outlined
                background-color="white"
                no-data-text="データが見つかりません"
                clearable
                :items="selectBoxFID7777"
                hide-details="auto"
              >
              </v-select>
            </v-col>
          </v-row>
          <!-- 確認用 -->
          <!-- <v-row>
            <v-col cols="0">
              <v-text-field
                label="事務所・事業所"
                :value="data.Recodes.FID7991.value"
                background-color="white"
                id="FID7991"
                class="userInfo7991"
                outlined
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
            <v-col cols="0">
              <v-text-field
                label="部署"
                :value="data.Recodes.FID7992.value"
                background-color="white"
                id="FID7992"
                class="userInfo7992"
                outlined
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
          </v-row> -->
          <v-row>
            <v-col cols="12" sm="12" md="6" lg="6">
              <div
                label=""
                :disabled="allDisabled8530"
                background-color="white"
                id="FID7994"
                class="plainText7994 text-h5"
                hide-details="auto"
              >
                必要情報の入力
              </div>
            </v-col>
          </v-row>
          <!-- 取り込みにより日付が異常値になった場合に強制的に変更する暫定対応 -->
          <v-row
            v-if="
              $store.state.role == 1 &&
              data.ReportID != null &&
              this.data.Recodes.FID8504.value != '申請前'
            "
          >
            <v-col cols="12" sm="12" md="12" lg="12">
              <v-btn
                @click="send(true)"
                color="yellow"
                >管理者による変更保存
              </v-btn>
              <span>※日付異常値、部署、従業員区分強制変更の管理者対応用</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="0">
              <v-select
                label="四輪車・バイク"
                v-model="data.Recodes.FID8269.value"
                :disabled="allDisabled8530_2"
                :rules="[rules.required, rules.containBox8269]"
                background-color="white"
                id="FID8269"
                class="selectBox8269"
                outlined
                no-data-text="データが見つかりません"
                clearable
                :items="selectBoxFID8269"
                hide-details="auto"
              >
              </v-select>
            </v-col>
            <v-col cols="0">
              <v-select
                label="使用用途"
                v-model="data.Recodes.FID7995.value"
                :disabled="allDisabled8530"
                background-color="white"
                id="FID7995"
                class="selectBox7995"
                outlined
                no-data-text="データが見つかりません"
                clearable
                :items="selectBoxFID7995"
                hide-details="auto"
              >
              </v-select>
            </v-col>
            <v-col cols="0">
              <v-dialog
                v-model="data.Recodes.FID7996.modal"
                :max-width="resizeWidth"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs">
                    <v-text-field
                      label="車両番号"
                      v-model="data.Recodes.FID7996.value"
                      :disabled="allDisabled8530"
                      :rules="[rules.required]"
                      background-color="white"
                      id="FID7996"
                      class="textfield7996"
                      append-icon="mdi-car"
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      readonly
                      hide-details="auto"
                    >
                    </v-text-field>
                  </div>
                </template>
                <car-Number
                  v-if="data.Recodes.FID7996.modal"
                  title="車両番号"
                  :value="data.Recodes.FID7996.value"
                  type="calculator"
                  v-on:ok="
                    (data.Recodes.FID7996.modal = false),
                      $set(data.Recodes.FID7996, 'value', $event)
                  "
                  :rules="[rules.required]"
                ></car-Number>
              </v-dialog>
            </v-col>
            <v-col cols="0">
              <v-text-field
                label="備考欄"
                v-model="data.Recodes.FID8334.value"
                :disabled="allDisabled8530"
                background-color="white"
                id="FID8334"
                class="textfield8334"
                outlined
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" sm="6" md="3" lg="3">
              <v-menu
                v-model="data.Recodes.FID8323.modal"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    label="運転免許証有効期限"
                    v-model="data.Recodes.FID8323.value"
                    :disabled="allDisabled8530 && $store.state.role != 1"
                    :rules="[rules.required]"
                    background-color="white"
                    id="FID8323"
                    class="inputTime8323"
                    append-icon="mdi-calendar"
                    outlined
                    v-on="on"
                    readonly
                    hide-details="auto"
                  >
                  </v-text-field> </template
                ><v-date-picker
                  v-model="data.Recodes.FID8323.value"
                  no-title
                  scrollable
                  locale="ja-jp"
                  :day-format="(date) => new Date(date).getDate()"
                  @input="data.Recodes.FID8323.modal = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6" sm="6" md="3" lg="3">
              <v-file-input
                label="運転免許証(表面)"
                v-model="uploadfile8001.file"
                :disabled="data.Recodes.FID8001.disabled || allDisabled8530"
                :rules="[
                  rules.imageExtension,
                  rules.fileRequired(
                    uploadfile8001.file,
                    data.Recodes.FID8001.value,
                    data.Recodes.FID8504.value == '承認済' || data.Recodes.FID11970.value != '' || data.Recodes.FID11971.value != '' 
                  ),
                ]"
                background-color="white"
                id="FID8001"
                class="fileInput8001"
                @change="changeFile8001"
                outlined
                prepend-icon="mdi-image"
                accept="image/*"
                hide-details="auto"
              >
              </v-file-input>
            </v-col>
            <v-col cols="6" sm="6" md="3" lg="3">
              <v-dialog v-model="data.Recodes.FID8009.modal" max-width=""
                ><template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs">
                    <v-btn
                      label="表示"
                      :disabled="
                        uploadfile8001.file == undefined &&
                        data.Recodes.FID8001.value == '' ||
                        data.Recodes.FID8009.url == ''
                      "
                      background-color="white"
                      id="FID8009"
                      class="viewImage8009 mt-2"
                      color="light-blue"
                      large
                      v-on="on"
                      @click="data.Recodes.FID8009.modal = true"
                      hide-details="auto"
                    >
                      表示</v-btn
                    >
                  </div></template
                >
                <v-img
                  label=""
                  background-color="white"
                  id="FID0"
                  class=""
                  :src="data.Recodes.FID8009.url"
                  @click="data.Recodes.FID8009.modal = false"
                  hide-details="auto"
                >
                </v-img>
              </v-dialog>
            </v-col>
            <v-col cols="6" sm="6" md="3" lg="3">
              <v-btn
                label="確認済"
                :disabled="data.Recodes.FID8313.disabled || inputRuleFID8313()"
                background-color="white"
                id="FID8313"
                class="button8313 mt-2"
                @click="onBtn8313"
                color="yellow lighten-2"
                hide-details="auto"
              >
                確認済
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" sm="6" md="3" lg="3">
              <v-menu
                v-model="data.Recodes.FID8324.modal"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    label="車検満了日"
                    v-model="data.Recodes.FID8324.value"
                    :disabled="allDisabled8530 && $store.state.role != 1"
                    :rules="[rules.required]"
                    background-color="white"
                    id="FID8324"
                    class="inputTime8324"
                    append-icon="mdi-calendar"
                    outlined
                    v-on="on"
                    readonly
                    hide-details="auto"
                    :hint="hint8324"
                    persistent-hint
                  >
                  </v-text-field> </template
                ><v-date-picker
                  v-model="data.Recodes.FID8324.value"
                  no-title
                  scrollable
                  locale="ja-jp"
                  :day-format="(date) => new Date(date).getDate()"
                  @input="data.Recodes.FID8324.modal = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6" sm="6" md="3" lg="3">
              <v-file-input
                label="車検証(写)・自賠責(写)"
                v-model="uploadfile8002.file"
                :disabled="data.Recodes.FID8002.disabled || allDisabled8530"
                :rules="[
                  rules.imageExtension,
                  rules.fileRequired(
                    uploadfile8002.file,
                    data.Recodes.FID8002.value,
                    data.Recodes.FID8504.value == '承認済' || data.Recodes.FID11970.value != '' || data.Recodes.FID11971.value != ''
                  ),
                ]"
                background-color="white"
                id="FID8002"
                class="fileInput8002"
                @change="changeFile8002"
                outlined
                prepend-icon="mdi-image"
                accept="image/*"
                hide-details="auto"
              >
              </v-file-input>
            </v-col>
            <v-col cols="6" sm="6" md="3" lg="3">
              <v-dialog v-model="data.Recodes.FID8010.modal" max-width=""
                ><template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs">
                    <v-btn
                      label="表示"
                      :disabled="
                        uploadfile8002.file == undefined &&
                        data.Recodes.FID8002.value == '' ||
                        data.Recodes.FID8010.url == ''
                      "
                      background-color="white"
                      id="FID8010"
                      class="viewImage8010 mt-2"
                      color="light-blue"
                      large
                      v-on="on"
                      @click="data.Recodes.FID8010.modal = true"
                      hide-details="auto"
                    >
                      表示</v-btn
                    >
                  </div></template
                >
                <v-img
                  label=""
                  background-color="white"
                  id="FID0"
                  class=""
                  :src="data.Recodes.FID8010.url"
                  @click="data.Recodes.FID8010.modal = false"
                  hide-details="auto"
                >
                </v-img>
              </v-dialog>
            </v-col>
            <v-col cols="6" sm="6" md="3" lg="3">
              <v-btn
                label="確認済"
                :disabled="data.Recodes.FID8314.disabled || inputRuleFID8314()"
                background-color="white"
                id="FID8314"
                class="button8314 mt-2"
                @click="onBtn8314"
                color="yellow lighten-2"
                hide-details="auto"
              >
                確認済</v-btn
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" sm="6" md="3" lg="3">
              <v-menu
                v-model="data.Recodes.FID8325.modal"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    label="自動車保険満了日"
                    v-model="data.Recodes.FID8325.value"
                    :disabled="allDisabled8530 && $store.state.role != 1"
                    :rules="[rules.required]"
                    background-color="white"
                    id="FID8325"
                    class="inputTime8325"
                    append-icon="mdi-calendar"
                    outlined
                    v-on="on"
                    readonly
                    hide-details="auto"
                  >
                  </v-text-field> </template
                ><v-date-picker
                  v-model="data.Recodes.FID8325.value"
                  no-title
                  scrollable
                  locale="ja-jp"
                  :day-format="(date) => new Date(date).getDate()"
                  @input="data.Recodes.FID8325.modal = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6" sm="6" md="3" lg="3">
              <v-file-input
                label="自動車保険証券(写)"
                v-model="uploadfile8003.file"
                :disabled="data.Recodes.FID8003.disabled || allDisabled8530"
                :rules="[
                  rules.imageExtension,
                  rules.fileRequired(
                    uploadfile8003.file,
                    data.Recodes.FID8003.value,
                    data.Recodes.FID8504.value == '承認済' || data.Recodes.FID11970.value != '' || data.Recodes.FID11971.value != ''
                  ),
                ]"
                background-color="white"
                id="FID8003"
                class="fileInput8003"
                @change="changeFile8003"
                outlined
                prepend-icon="mdi-image"
                accept="image/*"
                hide-details="auto"
              >
              </v-file-input>
            </v-col>
            <v-col cols="0">
              <v-dialog v-model="data.Recodes.FID8011.modal" max-width=""
                ><template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs">
                    <v-btn
                      label="表示"
                      :disabled="
                        uploadfile8003.file == undefined &&
                        data.Recodes.FID8003.value == '' ||
                        data.Recodes.FID8011.url == ''
                      "
                      background-color="white"
                      id="FID8011"
                      class="viewImage8011 mt-2"
                      color="light-blue"
                      large
                      v-on="on"
                      @click="data.Recodes.FID8011.modal = true"
                      hide-details="auto"
                    >
                      表示</v-btn
                    >
                  </div></template
                >
                <v-img
                  label=""
                  background-color="white"
                  id="FID0"
                  class=""
                  :src="data.Recodes.FID8011.url"
                  @click="data.Recodes.FID8011.modal = false"
                  hide-details="auto"
                >
                </v-img>
              </v-dialog>
            </v-col>
            <v-col cols="6" sm="6" md="3" lg="3">
              <v-btn
                label="確認済"
                :disabled="data.Recodes.FID8315.disabled || inputRuleFID8315()"
                background-color="white"
                id="FID8315"
                class="button8315 mt-2"
                @click="onBtn8315"
                color="yellow lighten-2"
                hide-details="auto"
              >
                確認済</v-btn
              >
            </v-col>
          </v-row>
          <template v-if="showTemplate8331"
            ><v-row>
              <v-col cols="6" sm="6" md="3" lg="3">
                <div
                  label=""
                  :disabled="allDisabled8530"
                  background-color="white"
                  id="FID8326"
                  class=""
                  hide-details="auto"
                ></div>
              </v-col>
              <v-col cols="6" sm="6" md="3" lg="3">
                <v-file-input
                  label="標準交付証明書(写し)"
                  v-model="uploadfile8327.file"
                  :disabled="data.Recodes.FID8327.disabled || allDisabled8530"
                  :rules="[
                    rules.imageExtension,
                    rules.fileRequired(
                      uploadfile8327.file,
                      data.Recodes.FID8327.value,
                      data.Recodes.FID8504.value == '承認済' || data.Recodes.FID11970.value != '' || data.Recodes.FID11971.value != ''
                    ),
                  ]"
                  background-color="white"
                  id="FID8327"
                  class="fileInput8327"
                  @change="changeFile8327"
                  outlined
                  prepend-icon="mdi-image"
                  accept="image/*"
                  hide-details="auto"
                >
                </v-file-input>
              </v-col>
              <v-col cols="6" sm="6" md="3" lg="3">
                <v-dialog v-model="data.Recodes.FID8328.modal" max-width=""
                  ><template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs">
                      <v-btn
                        label="表示"
                        :disabled="
                          uploadfile8327.file == undefined &&
                          data.Recodes.FID8327.value == ''
                        "
                        background-color="white"
                        id="FID8328"
                        class="viewImage8328 mt-2"
                        color="light-blue"
                        large
                        v-on="on"
                        @click="data.Recodes.FID8328.modal = true"
                        hide-details="auto"
                      >
                        表示</v-btn
                      >
                    </div></template
                  >
                  <v-img
                    label=""
                    background-color="white"
                    id="FID0"
                    class=""
                    :src="data.Recodes.FID8328.url"
                    @click="data.Recodes.FID8328.modal = false"
                    hide-details="auto"
                  >
                  </v-img>
                </v-dialog>
              </v-col>
              <v-col cols="6" sm="6" md="3" lg="3">
                <v-btn
                  label="確認済"
                  :disabled="
                    data.Recodes.FID8329.disabled || inputRuleFID8329()
                  "
                  background-color="white"
                  id="FID8329"
                  class="button8329 mt-2"
                  @click="onBtn8329"
                  color="yellow lighten-2"
                  hide-details="auto"
                >
                  確認済</v-btn
                >
              </v-col>
            </v-row> </template
          ><v-row>
            <v-col cols="0">
              <template><oathPage class="mt-3"> </oathPage></template>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" sm="6" md="3" lg="3">
              <div
                label=""
                :disabled="allDisabled8530"
                background-color="white"
                id="FID8527"
                class=""
                hide-details="auto"
              ></div>
            </v-col>
            <v-col cols="6" sm="6" md="4" lg="4">
              <v-btn
                label="同意する"
                :disabled="data.Recodes.FID8330.disabled || allDisabled8530"
                background-color="white"
                id="FID8330"
                class="button8330"
                @click="onBtn8330"
                color="red white--text"
                block
                style="font-size: 20px; letter-spacing: 10px"
                height="50"
                hide-details="auto"
              >
                <v-icon v-if="data.Recodes.FID9207.value != ''">
                  mdi-check-bold
                </v-icon>
                同意する
              </v-btn>
            </v-col>
            <v-col cols="6" sm="6" md="3" lg="3">
              <v-text-field
                label="同意日時"
                v-model="data.Recodes.FID9207.value"
                :disabled="data.Recodes.FID9207.disabled || allDisabled8530"
                :background-color="compClass9207"
                id="FID9207"
                class="textfield9207"
                outlined
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" sm="6" md="3" lg="3">
              <div
                label=""
                :disabled="allDisabled8530"
                background-color="white"
                id="FID8528"
                class=""
                hide-details="auto"
              ></div>
            </v-col>
            <v-col cols="6" sm="6" md="4" lg="4">
              <v-btn
                label="申請"
                background-color="white"
                id="FID8004"
                class="button8004"
                @click="onBtn8004"
                color="red white--text"
                block
                :disabled="data.Recodes.FID8004.disabled || allDisabled8530"
                style="font-size: 20px; letter-spacing: 10px"
                height="50"
                hide-details="auto"
              >
                申請</v-btn
              >
            </v-col>
            <v-col cols="3">
              <v-select
                label="申請ステータス"
                v-model="data.Recodes.FID8504.value"
                :items="selectBoxFID8504"
                :rules="[rules.required]"
                disabled
                background-color="white"
                id="FID8504"
                class="textfield8504"
                outlined
                hide-details="auto"
              >
              </v-select>
            </v-col>
            <v-col
              cols="2"
              v-if="data.Recodes.FID11971.value != ''"
              class="mt-4"
            >
              ※削除申請
            </v-col>
            <v-col
              cols="2"
              v-else-if="data.Recodes.FID11970.value != ''"
              class="mt-4"
            >
              ※更新申請
            </v-col>
          </v-row>
          <v-row v-if="data.Recodes.FID8504.value == '承認済'">
            <v-col cols="6" sm="6" md="3" lg="3"></v-col>
            <v-col cols="6" sm="6" md="4" lg="4">
              <v-btn
                background-color="white"
                @click="onBtn11970"
                color="blue white--text"
                block
                :disabled="
                  data.Recodes.FID8504.value != '承認済' ||
                  data.Recodes.FID7989.value !=
                    $store.state.user.attributes.name
                "
                style="font-size: 20px; letter-spacing: 10px"
                height="50"
                hide-details="auto"
              >
                更新申請
              </v-btn>
            </v-col>
            <v-col cols="6" sm="6" md="4" lg="4">
              <v-btn
                background-color="white"
                @click="onBtn11971"
                color="red white--text"
                block
                :disabled="
                  data.Recodes.FID8504.value != '承認済' ||
                  data.Recodes.FID7989.value !=
                    $store.state.user.attributes.name
                "
                style="font-size: 20px; letter-spacing: 10px"
                height="50"
                hide-details="auto"
              >
                削除申請
              </v-btn>
            </v-col>
          </v-row>
          <!--  承認履歴 -->
          <div v-show="showTemplate8525">
            <v-row
              v-if="
                typeof data.Recodes.FID8494.subReports != 'undefined' &&
                data.Recodes.FID8494.subReports.length > 0 &&
                data.Recodes.FID8494.subReports.some(subReport => 
                subReport.Recodes && subReport.Recodes.FID8542.value == 'グループリーダー' && subReport.Recodes.FID8496.value.includes('申請中'))
              "
            >
              <v-col
                cols="6"
              >
                <v-btn
                  color="red white--text"
                  @click="changeRole"
                >
                  役職強制変更
                </v-btn>
                <p>※承認役職を「グループリーダー」から「グループマネージャー」へ強制変更します。</p>
              </v-col>
            </v-row>
            <v-row
              v-if="
                typeof data.Recodes.FID8494.subReports != 'undefined' &&
                data.Recodes.FID8494.subReports.length > 0
              "
            >
              <v-col
                cols="12"
                v-for="(report, i) in data.Recodes.FID8494.subReports"
                :key="`[8494-${i}`"
              >
                <approvalHistory1
                  label=""
                  :disabled="allDisabled8530"
                  background-color="white"
                  :id="'FID8494-' + i"
                  class="subPage8494"
                  v-on:send="send"
                  ref="subPage8494"
                  :Report="report"
                  :subindex="i"
                  :daleteReports.sync="data.DeleteSubReports"
                  v-on:clickSubmit="saveSubPage"
                  :myInit="subInit"
                  FID="FID8494"
                  :mainRecodes="data.Recodes"
                  hide-details="auto"
                >
                </approvalHistory1>
              </v-col>
            </v-row>
          </div>

          <v-row>
            <!-- 顧客環境は管理者にのみ表示 -->
            <!-- ローカル環境は常にどちらかが表示される -->
            <v-col align="left">
              <v-btn
                v-if="$store.state.role == 1"
                @click="deleteModal = true"
                :disabled="data.ReportID == null"
                color="red white--text"
                >管理者による削除
              </v-btn>
              <v-btn
                v-else-if="viewFID8585"
                @click="deleteModal = true"
                color="red white--text"
                >削除
              </v-btn>
              <v-dialog v-model="deleteModal" max-width="400">
                <v-card>
                  <v-card-title class="text-h5"> 削除しますか？ </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      class="mr-3"
                      color="green darken-1 white--text"
                      @click="deleteModal = false"
                      large
                    >
                      いいえ
                    </v-btn>
                    <v-btn color="red darken-1 white--text" @click="del" large>
                      はい
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
            <v-col v-if="showTemplate9575">
              <v-btn
                @click="save9575()"
                color="light-green white--text"
                fixed
                bottom
                :style="'right:+50px;'"
                >書類確認</v-btn
              >
            </v-col>
            <v-col v-if="showTemplateTemporary">
              <v-btn
                @click="send(true)"
                color="yellow"
                fixed
                bottom
                :style="'right:+50px;'"
                >一時保存</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </div>
    </v-container>
  </div>
</template>

<script>
import store from "../store/vuex.js";
import { RepositoryFactory } from "../api/RepositoryFactory";
const api = RepositoryFactory.get("seisei_back");

import carNumber from "../views/carNumberBase";
import { myCarDocumentCheck } from "./specialMethod";
import oathPage from "../views/oathPage";
import { collBtn8004No1 } from "./specialMethod";
import approvalHistory1 from "../views/approvalHistory1";
import { getDivisionAll } from "./specialMethod";
import { getDepartmentAll } from "./specialMethod";
const eneosCar = RepositoryFactory.get("eneosCar");

export default {
  name: "Home",
  components: {
    carNumber,
    oathPage,
    approvalHistory1,
  },
  data() {
    return {
      isLoading: false,
      escapeValidation: false,
      showChild: true,
      subInit: false,
      disabled: false,
      data: {
        PageID: 1156,
        ReportID: null, //新規ならnull、更新ならidがある
        Recodes: {
          FID7989: { value: "", formType: 18, disabled: false },
          FID7990: { value: "", formType: 18, disabled: false },
          FID7991: { value: "", formType: 18, disabled: false },
          FID7992: { value: "", formType: 18, disabled: false },
          FID7993: { value: "", formType: 18, disabled: false },
          FID8269: { value: "四輪車", formType: 2, disabled: false },
          FID7995: { value: "通勤車", formType: 2, disabled: false },
          FID7996: { value: "", formType: 1, disabled: false, modal: false },
          FID8334: { value: "", formType: 1, disabled: false },
          FID8323: {
            value: new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate(),
              new Date().getHours() + 9
            )
              .toISOString()
              .match(/^\d{4}-\d{2}-\d{2}/g)
              .pop(),
            formType: 3,
            disabled: false,
            modal: false,
          },
          FID8001: {
            value: "",
            formType: 9,
            disabled: false,
            form_id: 8001,
            url: "",
            view: true,
          },
          FID8009: {
            value: "",
            formType: 10,
            disabled: false,
            url: "",
            modal: false,
          },
          FID8313: { value: "", formType: 16, disabled: false },
          FID8324: {
            value: new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate(),
              new Date().getHours() + 9
            )
              .toISOString()
              .match(/^\d{4}-\d{2}-\d{2}/g)
              .pop(),
            formType: 3,
            disabled: false,
            modal: false,
          },
          FID8002: {
            value: "",
            formType: 9,
            disabled: false,
            form_id: 8002,
            url: "",
            view: true,
          },
          FID8010: {
            value: "",
            formType: 10,
            disabled: false,
            url: "",
            modal: false,
          },
          FID8314: { value: "", formType: 16, disabled: false },
          FID8325: {
            value: new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate(),
              new Date().getHours() + 9
            )
              .toISOString()
              .match(/^\d{4}-\d{2}-\d{2}/g)
              .pop(),
            formType: 3,
            disabled: false,
            modal: false,
          },
          FID8003: {
            value: "",
            formType: 9,
            disabled: false,
            form_id: 8003,
            url: "",
            view: true,
          },
          FID8011: {
            value: "",
            formType: 10,
            disabled: false,
            url: "",
            modal: false,
          },
          FID8315: { value: "", formType: 16, disabled: false },
          FID8327: {
            value: "",
            formType: 9,
            disabled: false,
            form_id: 8327,
            url: "",
            view: true,
          },
          FID8328: {
            value: "",
            formType: 10,
            disabled: false,
            url: "",
            modal: false,
          },
          FID8329: { value: "", formType: 16, disabled: false },
          FID8330: { value: "", formType: 16, disabled: false },
          FID9207: { value: "", formType: 1, disabled: true },
          FID8004: { value: "", formType: 16, disabled: true },
          FID11970: { value: "", formType: 16, disabled: true }, //変更申請
          FID11971: { value: "", formType: 16, disabled: true }, //削除申請
          FID8504: { value: "申請前", formType: 1, disabled: false },
          FID8494: {
            value: "PID1214",
            formType: 17,
            disabled: false,
            subReports: [{}],
          },
          FID8503: { value: "", formType: 20, disabled: false },
          FID8585: { value: "", formType: 22, disabled: false },
          //
          deleteFlag: false,
        },
        DeleteSubReports: [],
      },
      selectBoxFID8504: [
        "申請前",
        "申請中(1)",
        "申請中(2)",
        "申請中(3)",
        "申請中(4)",
        "承認済",
        "棄却",
        "差し戻し",
        "削除",
        "変更",
      ],
      selectBoxFID8269: ["四輪車", "バイク"],
      selectBoxFID7995: ["通勤車", "通勤車/業務使用車"], //変更
      uploadfile8001: {
        file: null,
        url: "",
        data: { name: "", size: 0, type: "" },
      },
      uploadfile8002: {
        file: null,
        url: "",
        data: { name: "", size: 0, type: "" },
      },
      uploadfile8003: {
        file: null,
        url: "",
        data: { name: "", size: 0, type: "" },
      },
      uploadfile8327: {
        file: null,
        url: "",
        data: { name: "", size: 0, type: "" },
      },
      rules: {
        required: (value) => !!value || "必須項目です",
        imageExtension: (value) => {
          if (value == null || JSON.stringify(value) == "{}") return true;
          const splitValue = value.name.split(".");
          const extension = splitValue[splitValue.length - 1];
          return extension == "jpeg" || extension == "jpg" || extension == "png"
            ? true
            : "対応していないファイルです";
        },
        containBox8269: (value) => {
          if (typeof this.selectBoxFID8269 == "undefined") {
            return true;
          }
          return this.selectBoxFID8269.includes(value) ||
            value == "" ||
            value == null
            ? true
            : "選択肢にない項目が入力されています";
        },
        fileRequired: (file, value, flag) => {
          if (file == null && value == "" && !flag) return "ファイルを選択してください";
          return true;
        },
      },
      deleteModal: false,
      copyFlag: false,
      sendCopyFlag: false,
      noBack: false,
      backCount: -1,
      allDivisinos: [],
      allDepartments: [],
      selectBoxFID7777: [],
    };
  },
  async created() {
    await this.getInit();
  },
  computed: {
    resizeWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "270px";
        case "sm":
          return "500px";
        case "md":
          return "600px";
        case "lg":
          return "500px";
        case "xl":
          return "500px";
        default:
          return "500px";
      }
    },
    hint8324: function () {
      return "バイクは自賠責保険満了日";
    },
    showTemplate8331() {
      if (this.data.Recodes.FID8269.value == "バイク") {
        return true;
      }
      return false;
    },
    compClass9207: function () {
      return "grey";
    },
    showTemplate8525() {
      if (this.data.Recodes.FID8504.value != "申請前") {
        return true;
      }
      return false;
    },
    showTemplate8503() {
      return true;
    },
    viewFID8585: function () {
      if (process.env.VUE_APP_BASE_URL == "http://localhost:8128") {
        return true;
      }
      return false;
    },
    showTemplate8747() {
      if (this.data.Recodes.FID8504.value == "申請前") {
        return true;
      }
      return false;
    },
    showTemplate9575() {
      if (
        this.data.Recodes.FID8504.value != "申請前" &&
        this.$store.state.params.FID8134.value == "マイカー添付書類確認者"
      ) {
        return true;
      }
      return false;
    },
    showTemplateTemporary() {
      if (this.data.Recodes.FID8504.value == "申請前") {
        return true;
      }
      return false;
    },
    allDisabled8530() {
      let data = this.data;
      if (
        data.Recodes.FID8504.value == "申請前" ||
        data.Recodes.FID8504.value == "棄却"
      ) {
        return false;
      }
      if (
        data.Recodes.FID8504.value == "承認済" &&
        data.Recodes.FID7989.value == this.$store.state.user.attributes.name
      ) {
        return false;
      }
      return true;
    },
    allDisabled8530_2() {
      //四輪車・バイクの変更は不可
      let data = this.data;
      if (
        data.Recodes.FID8504.value == "申請前" ||
        data.Recodes.FID8504.value == "棄却"
      ) {
        return false;
      }
      if (
        data.Recodes.FID8504.value == "承認済" &&
        data.Recodes.FID7989.value == this.$store.state.user.attributes.name
      ) {
        return false;
      }
      return true;
    },
  },
  watch: {
    $route() {
      this.getInit();
    },
    data: {
      handler: async function (data) {
        if (
          data.Recodes.FID8504.value == "申請前" ||
          data.Recodes.FID8504.value == "棄却" ||
          data.Recodes.FID8504.value == "承認済"
        ) {
          this.data.Recodes.FID8001.disabled = false;
        } else {
          this.data.Recodes.FID8001.disabled = true;
        }
        if (
          myCarDocumentCheck(data) &&
          data.Recodes.FID8313.value == "" &&
          data.Recodes.FID8001.value != ""
        ) {
          this.data.Recodes.FID8313.disabled = false;
        } else {
          this.data.Recodes.FID8313.disabled = true;
        }
        if (
          data.Recodes.FID8504.value == "申請前" ||
          data.Recodes.FID8504.value == "棄却" ||
          data.Recodes.FID8504.value == "承認済"
        ) {
          this.data.Recodes.FID8002.disabled = false;
        } else {
          this.data.Recodes.FID8002.disabled = true;
        }
        if (
          myCarDocumentCheck(data) &&
          data.Recodes.FID8314.value == "" &&
          data.Recodes.FID8002.value != ""
        ) {
          this.data.Recodes.FID8314.disabled = false;
        } else {
          this.data.Recodes.FID8314.disabled = true;
        }
        if (
          data.Recodes.FID8504.value == "申請前" ||
          data.Recodes.FID8504.value == "棄却" ||
          data.Recodes.FID8504.value == "承認済"
        ) {
          this.data.Recodes.FID8003.disabled = false;
        } else {
          this.data.Recodes.FID8003.disabled = true;
        }
        if (
          myCarDocumentCheck(data) &&
          data.Recodes.FID8315.value == "" &&
          data.Recodes.FID8003.value != ""
        ) {
          this.data.Recodes.FID8315.disabled = false;
        } else {
          this.data.Recodes.FID8315.disabled = true;
        }
        if (
          data.Recodes.FID8504.value == "申請前" ||
          data.Recodes.FID8504.value == "棄却" ||
          data.Recodes.FID8504.value == "承認済"
        ) {
          this.data.Recodes.FID8327.disabled = false;
        } else {
          this.data.Recodes.FID8327.disabled = true;
        }
        if (
          myCarDocumentCheck(data) &&
          data.Recodes.FID8329.value == "" &&
          data.Recodes.FID8327.value != ""
        ) {
          this.data.Recodes.FID8329.disabled = false;
        } else {
          this.data.Recodes.FID8329.disabled = true;
        }
        if (data.Recodes.FID8330.value == "") {
          this.data.Recodes.FID8330.disabled = false;
        } else {
          this.data.Recodes.FID8330.disabled = true;
        }
        if (
          data.Recodes.FID8330.value == "" ||
          data.Recodes.FID8004.value == "申請中(1)" ||
          data.Recodes.FID8504.value == "申請中(2)" ||
          data.Recodes.FID8504.value == "申請中(3)" ||
          data.Recodes.FID8504.value == "承認済"
        ) {
          this.data.Recodes.FID8004.disabled = true;
        } else {
          this.data.Recodes.FID8004.disabled = false;
        }
        if (data.Recodes.FID8504.value != "申請前") {
          this.data.Recodes.FID8585.disabled = true;
        } else {
          this.data.Recodes.FID8585.disabled = false;
        }
      },
      deep: true,
    },

    uploadfile8001: {
      deep: true,
      handler: async function () {
        if (
          this.uploadfile8001.file == undefined || 
          (Object.keys(this.uploadfile8001.file).length === 0 && this.uploadfile8001.file.constructor === Object)
        ) {
          return;
        }

        this.data.Recodes.FID8009.url = URL.createObjectURL(
          this.uploadfile8001.file
        );
      },
    },
    uploadfile8002: {
      deep: true,
      handler: async function () {
        if (
          this.uploadfile8002.file == undefined ||
          (Object.keys(this.uploadfile8002.file).length === 0 && this.uploadfile8002.file.constructor === Object)
        ) {
          return;
        }
        this.data.Recodes.FID8010.url = URL.createObjectURL(
          this.uploadfile8002.file
        );
      },
    },
    uploadfile8003: {
      deep: true,
      handler: async function () {
        if (
          this.uploadfile8003.file == undefined ||
          (Object.keys(this.uploadfile8003.file).length === 0 && this.uploadfile8003.file.constructor === Object)
        ) {
          return;
        }
        this.data.Recodes.FID8011.url = URL.createObjectURL(
          this.uploadfile8003.file
        );
      },
    },
    uploadfile8327: {
      deep: true,
      handler: async function () {
        if (
          this.uploadfile8327.file == undefined ||
          (Object.keys(this.uploadfile8327.file).length === 0 && this.uploadfile8327.file.constructor === Object)
        ) {
          return;
        }
        this.data.Recodes.FID8328.url = URL.createObjectURL(
          this.uploadfile8327.file
        );
      },
    },
  },
  methods: {
    async getInit() {
      try {
        //初期処理
        this.isLoading = true;
        //
        if (this.$route.query.copyFlag) {
          this.reportCopy();
        } else if (this.$route.params.report_id != null) {
          this.data.ReportID = Number(this.$route.params.report_id);
          //データ取得
          let data = {
            PageID: this.data.PageID,
            ReportID: Number(this.data.ReportID),
          };
          const result = await api.ReportDetailV2(data);
          this.RecodeCopy(this.data, result.Recodes);
          //初期処理
          this.initFile8001();
          this.initFile8002();
          this.initFile8003();
          this.initFile8327();
        }
        if (
          process.env.VUE_APP_BASE_URL == "http://localhost:8128" &&
          this.$route.params.report_id == null
        ) {
          this.data.Recodes.FID7996.value = "大阪1あ1234";
        }
        //データ取得処理
        //init処理
        this.initFID7989();
        await this.getUserInfoParams();
        this.initFID7990();
        this.initFID7991();
        this.initFID7992();
        this.initFID7993();
        this.initFID7995();
        this.initCheck8323();
        await this.viewInitProcess8009();
        this.initCheck8313();
        this.initCheck8324();
        await this.viewInitProcess8010();
        this.initCheck8314();
        this.initCheck8325();
        await this.viewInitProcess8011();
        this.initCheck8315();
        await this.viewInitProcess8328();
        this.initCheck8329();
        this.initSubPage8494();
        if (this.$store.state.role == 1 && this.data.ReportID != null) {
          await this.getAllDepartmentsForAdmin()
        }
        //読み込み終了
        this.isLoading = false;
      } catch (e) {
        console.log(e);
        alert("読み込みに失敗しました");
        this.back();
      }
    },
    RecodeCopy(data, response) {
      for (const [FID] of Object.entries(data.Recodes)) {
        if (typeof response[FID] != "undefined") {
          data.Recodes[FID].value = response[FID].value;
        }
        if (
          typeof response[FID] != "undefined" &&
          typeof response[FID].subReports != "undefined" &&
          response[FID].subReports != null
        ) {
          data.Recodes[FID].subReports = response[FID].subReports;
        }
      }
    },
    changeFile8001(file) {
      if (file !== undefined && file !== null) {
        this.data.Recodes.FID8001.view = false;
        this.uploadfile8001.file = file;
        this.uploadfile8001.data.size = this.uploadfile8001.file.size;
        this.uploadfile8001.data.type = this.uploadfile8001.file.type;
        this.uploadfile8001.data.form_id = 8001;
        this.data.Recodes.FID8001.file_type = this.uploadfile8001.file.type;
        this.data.Recodes.FID8001.url = URL.createObjectURL(file);
        this.$nextTick(() => {
          this.data.Recodes.FID8001.view = true;
        });
      } else {
        this.uploadfile8001 = {
          file: null,
          url: "",
          data: { name: "", size: 0, type: "" },
        };
        this.data.Recodes.FID8001.file_type = "";
        this.data.Recodes.FID8001.url = null;
      }
    },
    async onBtn8313() {
      //処理を記載
      const date = this.getDateTime();
      this.data.Recodes.FID8313.value = date; //tag:日付+時間
      this.data.Recodes.FID8313.disabled = true; //1回だけ
    },
    inputRuleFID8313() {
      let data = this.data;
      if (
        myCarDocumentCheck(data) &&
        data.Recodes.FID8313.value == "" &&
        data.Recodes.FID8001.value != ""
      ) {
        return false;
      }
      return true;
    },
    getDateTime() {
      let date = new Date();
      let years = date.getFullYear().toString();
      let month = (date.getMonth() + 1).toString().padStart(2, "0");
      let days = date.getDate().toString().padStart(2, "0");
      let hours = date.getHours().toString().padStart(2, "0");
      let minutes = date.getMinutes().toString().padStart(2, "0");
      let seconds = date.getSeconds().toString().padStart(2, "0");
      return (
        years +
        "-" +
        month +
        "-" +
        days +
        " " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds
      );
    },
    changeFile8002(file) {
      if (file !== undefined && file !== null) {
        this.data.Recodes.FID8002.view = false;
        this.uploadfile8002.file = file;
        this.uploadfile8002.data.size = this.uploadfile8002.file.size;
        this.uploadfile8002.data.type = this.uploadfile8002.file.type;
        this.uploadfile8002.data.form_id = 8002;
        this.data.Recodes.FID8002.file_type = this.uploadfile8002.file.type;
        this.data.Recodes.FID8002.url = URL.createObjectURL(file);
        this.$nextTick(() => {
          this.data.Recodes.FID8002.view = true;
        });
      } else {
        this.uploadfile8002 = {
          file: null,
          url: "",
          data: { name: "", size: 0, type: "" },
        };
        this.data.Recodes.FID8002.file_type = "";
        this.data.Recodes.FID8002.url = null;
      }
    },
    async onBtn8314() {
      //処理を記載
      const date = this.getDateTime();
      this.data.Recodes.FID8314.value = date; //tag:日付+時間
      this.data.Recodes.FID8314.disabled = true; //1回だけ
    },
    inputRuleFID8314() {
      let data = this.data;
      if (
        myCarDocumentCheck(data) &&
        data.Recodes.FID8314.value == "" &&
        data.Recodes.FID8002.value != ""
      ) {
        return false;
      }
      return true;
    },
    changeFile8003(file) {
      if (file !== undefined && file !== null) {
        this.data.Recodes.FID8003.view = false;
        this.uploadfile8003.file = file;
        this.uploadfile8003.data.size = this.uploadfile8003.file.size;
        this.uploadfile8003.data.type = this.uploadfile8003.file.type;
        this.uploadfile8003.data.form_id = 8003;
        this.data.Recodes.FID8003.file_type = this.uploadfile8003.file.type;
        this.data.Recodes.FID8003.url = URL.createObjectURL(file);
        this.$nextTick(() => {
          this.data.Recodes.FID8003.view = true;
        });
      } else {
        this.uploadfile8003 = {
          file: null,
          url: "",
          data: { name: "", size: 0, type: "" },
        };
        this.data.Recodes.FID8003.file_type = "";
        this.data.Recodes.FID8003.url = null;
      }
    },
    async onBtn8315() {
      //処理を記載
      const date = this.getDateTime();
      this.data.Recodes.FID8315.value = date; //tag:日付+時間
      this.data.Recodes.FID8315.disabled = true; //1回だけ
    },
    inputRuleFID8315() {
      let data = this.data;
      if (
        myCarDocumentCheck(data) &&
        data.Recodes.FID8315.value == "" &&
        data.Recodes.FID8003.value != ""
      ) {
        return false;
      }
      return true;
    },
    changeFile8327(file) {
      if (file !== undefined && file !== null) {
        this.data.Recodes.FID8327.view = false;
        this.uploadfile8327.file = file;
        this.uploadfile8327.data.size = this.uploadfile8327.file.size;
        this.uploadfile8327.data.type = this.uploadfile8327.file.type;
        this.uploadfile8327.data.form_id = 8327;
        this.data.Recodes.FID8327.file_type = this.uploadfile8327.file.type;
        this.data.Recodes.FID8327.url = URL.createObjectURL(file);
        this.$nextTick(() => {
          this.data.Recodes.FID8327.view = true;
        });
      } else {
        this.uploadfile8327 = {
          file: null,
          url: "",
          data: { name: "", size: 0, type: "" },
        };
        this.data.Recodes.FID8327.file_type = "";
        this.data.Recodes.FID8327.url = null;
      }
    },
    async onBtn8329() {
      //処理を記載
      const date = this.getDateTime();
      this.data.Recodes.FID8329.value = date; //tag:日付+時間
      this.data.Recodes.FID8329.disabled = true; //1回だけ
    },
    inputRuleFID8329() {
      let data = this.data;
      if (
        myCarDocumentCheck(data) &&
        data.Recodes.FID8329.value == "" &&
        data.Recodes.FID8327.value != ""
      ) {
        return false;
      }
      return true;
    },
    async onBtn8330() {
      const validationCheck = await this.checkValidation();
      if (!validationCheck) {
        return;
      }
      //処理を記載
      const date = this.getDateTime();
      this.data.Recodes.FID8330.value = date; //tag:日付+時間
      this.data.Recodes.FID9207.value = this.data.Recodes.FID8330.value;
      const val = this.data.Recodes.FID8330.value.split(" ");
      this.data.Recodes.FID9207.date = val[0];
      this.data.Recodes.FID9207.time = val[1];
    },
    async onBtn11970() {
      let check = await this.checkValidation();
      if (!check) {
        return;
      }
      this.data.Recodes.FID11970.value = this.getDateTime();
      await this.onBtn8004();
    },
    async onBtn11971() {
      let check = await this.checkValidation();
      if (!check) {
        return;
      }
      this.data.Recodes.FID11971.value = this.getDateTime();
      await this.onBtn8004();
    },
    async onBtn8004() {
      let check = await this.checkValidation();
      if (!check) {
        return;
      }
      //処理を記載
      //申請処理
      const res = await collBtn8004No1(this.data); //特殊関数の呼び出し
      if (res == "first error") {
        this.data.Recodes.FID8004.disabled = false;
        return;
      }
      await this.save8503();
    },
    subPageAdd(FID, index) {
      this.isLoading = true;
      if (
        typeof this.data.Recodes[FID] == "undefined" ||
        typeof this.data.Recodes[FID].subReports == "undefined"
      ) {
        index = 0;
        this.data.Recodes[FID].subReports = [];
      }
      if (typeof index == "undefined") {
        index = this.data.Recodes[FID].subReports.length;
      }
      let max = 20;
      if (this.data.Recodes[FID].subReports.length < max) {
        this.data.Recodes[FID].subReports.splice(index, 0, {});
      }
      this.isLoading = false;
    },
    subPageDel(FID, index) {
      //this.subInit = false;
      let min = 1;
      if (typeof index == "undefined") {
        index = this.data.Recodes[FID].subReports.length - 1;
      }
      if (this.data.Recodes[FID].subReports.length > min) {
        this.data.DeleteSubReports = this.data.DeleteSubReports.concat(
          this.data.Recodes[FID].subReports.splice(index, 1)
        );
      }
      //this.subInit = true;
    },
    saveSubPage(FID, subindex, reports) {
      this.data.Recodes[FID].subReports.splice(subindex, 1, reports);
      if (reports.DeleteSubReports.length == 1) {
        this.data.DeleteSubReports = this.data.DeleteSubReports.concat(
          reports.DeleteSubReports.pop()
        );
      }
    },
    async save8503() {
      this.data.Recodes.FID8503.value = this.getDateTime();
      await this.send();
    },
    async save8747() {
      await this.send();
    },
    async save9575() {
      await this.send();
    },
    async save9576() {
      await this.send();
    },
    initFID7989() {
      if (this.data.Recodes.FID7989.value == "") {
        this.data.Recodes.FID7989.value = this.$store.state.user.username;
      }
    },
    async getUserInfoParams() {
      if (
        !this.$store.state.params ||
        typeof this.$store.state.params != "object" ||
        !Object.keys(this.$store.state.params).length
      ) {
        const result = await api.EmployeeDetail();
        store.commit("setParams", result.Recodes);
      }
    },
    initFID7990() {
      if (this.$store.state.params.FID7762.value == undefined) {
        console.log(
          "ユーザ設定ページ追加フォーム取得の対象フォームIDが見つかりませんでした"
        );
      }
      if (this.data.Recodes.FID7990.value == "") {
        this.data.Recodes.FID7990.value =
          this.$store.state.params.FID7762.value || "";
      }
    },
    initFID7991() {
      if (this.$store.state.params.FID7767.value == undefined) {
        console.log(
          "ユーザ設定ページ追加フォーム取得の対象フォームIDが見つかりませんでした"
        );
      }
      if (this.data.Recodes.FID7991.value == "") {
        this.data.Recodes.FID7991.value =
          this.$store.state.params.FID7767.value || "";
      }
    },
    initFID7992() {
      if (this.$store.state.params.FID7776.value == undefined) {
        console.log(
          "ユーザ設定ページ追加フォーム取得の対象フォームIDが見つかりませんでした"
        );
      }
      if (this.data.Recodes.FID7992.value == "") {
        this.data.Recodes.FID7992.value =
          this.$store.state.params.FID7776.value || "";
      }
    },
    initFID7993() {
      if (this.$store.state.params.FID7777.value == undefined) {
        console.log(
          "ユーザ設定ページ追加フォーム取得の対象フォームIDが見つかりませんでした"
        );
      }
      if (this.data.Recodes.FID7993.value == "") {
        this.data.Recodes.FID7993.value =
          this.$store.state.params.FID7777.value || "";
      }
    },
    initFID7995() {
      //旧選択肢を修正
      if (
        this.data.Recodes.FID7995.value == "業務" ||
        this.data.Recodes.FID7995.value == "業務利用"
      ) {
        this.data.Recodes.FID7995.value = "通勤車/業務使用車";
      } else if (this.data.Recodes.FID7995.value == "通勤") {
        this.data.Recodes.FID7995.value = "通勤車";
      }
    },
    initCheck8323() {
      if (typeof this.data.Recodes.FID8323 == "undefined") {
        this.data.Recodes.FID8323 = { value: "", formType: 3 };
      }
      if (this.data.Recodes.FID8323.value == "") {
        this.data.Recodes.FID8323.value = new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate(),
          new Date().getHours() + 9
        )
          .toISOString()
          .match(/^\d{4}-\d{2}-\d{2}/g)
          .pop();
      }
    },
    async viewInitProcess8009() {
      if (this.data.Recodes.FID8001.value != "") {
        this.data.Recodes.FID8009.url = await api.FileAccess(
          this.data.Recodes.FID8001
        );
      }
    },
    initCheck8313() {
      if (typeof this.data.Recodes.FID8313 == "undefined") {
        this.data.Recodes.FID8313 = { value: "", formType: 16 };
      }
      if (this.data.Recodes.FID8313.value != "") {
        this.data.Recodes.FID8313.disabled = true; //1回だけ
      }
    },
    initCheck8324() {
      if (typeof this.data.Recodes.FID8324 == "undefined") {
        this.data.Recodes.FID8324 = { value: "", formType: 3 };
      }
      if (this.data.Recodes.FID8324.value == "") {
        this.data.Recodes.FID8324.value = new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate(),
          new Date().getHours() + 9
        )
          .toISOString()
          .match(/^\d{4}-\d{2}-\d{2}/g)
          .pop();
      }
    },
    async viewInitProcess8010() {
      if (this.data.Recodes.FID8002.value != "") {
        this.data.Recodes.FID8010.url = await api.FileAccess(
          this.data.Recodes.FID8002
        );
      }
    },
    initCheck8314() {
      if (typeof this.data.Recodes.FID8314 == "undefined") {
        this.data.Recodes.FID8314 = { value: "", formType: 16 };
      }
      if (this.data.Recodes.FID8314.value != "") {
        this.data.Recodes.FID8314.disabled = true; //1回だけ
      }
    },
    initCheck8325() {
      if (typeof this.data.Recodes.FID8325 == "undefined") {
        this.data.Recodes.FID8325 = { value: "", formType: 3 };
      }
      if (this.data.Recodes.FID8325.value == "") {
        this.data.Recodes.FID8325.value = new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate(),
          new Date().getHours() + 9
        )
          .toISOString()
          .match(/^\d{4}-\d{2}-\d{2}/g)
          .pop();
      }
    },
    async viewInitProcess8011() {
      if (this.data.Recodes.FID8003.value != "") {
        this.data.Recodes.FID8011.url = await api.FileAccess(
          this.data.Recodes.FID8003
        );
      }
    },
    initCheck8315() {
      if (typeof this.data.Recodes.FID8315 == "undefined") {
        this.data.Recodes.FID8315 = { value: "", formType: 16 };
      }
      if (this.data.Recodes.FID8315.value != "") {
        this.data.Recodes.FID8315.disabled = true; //1回だけ
      }
    },
    async viewInitProcess8328() {
      if (this.data.Recodes.FID8327.value != "") {
        this.data.Recodes.FID8328.url = await api.FileAccess(
          this.data.Recodes.FID8327
        );
      }
    },
    initCheck8329() {
      if (typeof this.data.Recodes.FID8329 == "undefined") {
        this.data.Recodes.FID8329 = { value: "", formType: 16 };
      }
      if (this.data.Recodes.FID8329.value != "") {
        this.data.Recodes.FID8329.disabled = true; //1回だけ
      }
    },
    initSubPage8494() {
      for (let i = this.data.Recodes.FID8494.subReports.length; i < 1; i++) {
        this.data.Recodes.FID8494.subReports.push({});
      }
    },
    initFile8001() {
      this.uploadfile8001.file = {};
    },
    initFile8002() {
      this.uploadfile8002.file = {};
    },
    initFile8003() {
      if (this.data.Recodes.FID8003.value != "") {
        this.uploadfile8003.file = {};
        this.data.Recodes.FID8003.disabled = true;
      }
    },
    initFile8327() {
      if (this.data.Recodes.FID8327.value != "") {
        this.uploadfile8327.file = {};
        this.data.Recodes.FID8327.disabled = true;
      }
    },
    async sendFile8001() {
      try {
        if (
          this.uploadfile8001.file !== undefined &&
          this.uploadfile8001.file !== null &&
          this.uploadfile8001.data.type != ""
        ) {
          this.data.Recodes.FID8001.value = await api.UploadFile(
            this.uploadfile8001.data,
            this.uploadfile8001.file
          );
        }
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    viewSendProcess8009() {
      if (this.data.Recodes.FID8001.value != "") {
        this.data.Recodes.FID8009.value = this.data.Recodes.FID8001.value;
      }
    },
    async sendFile8002() {
      try {
        if (
          this.uploadfile8002.file !== undefined &&
          this.uploadfile8002.file !== null &&
          this.uploadfile8002.data.type != ""
        ) {
          this.data.Recodes.FID8002.value = await api.UploadFile(
            this.uploadfile8002.data,
            this.uploadfile8002.file
          );
        }
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    viewSendProcess8010() {
      if (this.data.Recodes.FID8002.value != "") {
        this.data.Recodes.FID8010.value = this.data.Recodes.FID8002.value;
      }
    },
    async sendFile8003() {
      try {
        if (
          this.uploadfile8003.file !== undefined &&
          this.uploadfile8003.file !== null &&
          this.uploadfile8003.data.type != ""
        ) {
          this.data.Recodes.FID8003.value = await api.UploadFile(
            this.uploadfile8003.data,
            this.uploadfile8003.file
          );
        }
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    viewSendProcess8011() {
      if (this.data.Recodes.FID8003.value != "") {
        this.data.Recodes.FID8011.value = this.data.Recodes.FID8003.value;
      }
    },
    async sendFile8327() {
      try {
        if (
          this.uploadfile8327.file !== undefined &&
          this.uploadfile8327.file !== null &&
          this.uploadfile8327.data.type != ""
        ) {
          this.data.Recodes.FID8327.value = await api.UploadFile(
            this.uploadfile8327.data,
            this.uploadfile8327.file
          );
        }
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    viewSendProcess8328() {
      if (this.data.Recodes.FID8327.value != "") {
        this.data.Recodes.FID8328.value = this.data.Recodes.FID8327.value;
      }
    },

    checkValidation: async function () {
      let form = this.$refs.form;
      let check = form.validate();
      if (!check) {
        const firstError = form.inputs.find((input) => input.hasError);
        console.log("firstError", firstError);
        this.$vuetify.goTo(firstError, { offset: 100 });
        return check;
      }
      if (typeof this.$refs.subPage8494 != "undefined") {
        for (let i = 0; i < this.$refs.subPage8494.length; i++) {
          let tmp = await this.$refs.subPage8494[i].checkValidation();
          if (tmp == false) {
            check = false;
          }
        }
      }
      //車両番号の重複チェック

      return check;
    },
    async send(fixBugDate = false) {
      if (fixBugDate == true) this.escapeValidation = true;
      const result = this.escapeValidation
        ? true
        : await this.checkValidation();

      if (result) {
        this.isLoading = true;
        await this.sendFile8001();
        this.viewSendProcess8009();
        await this.sendFile8002();
        this.viewSendProcess8010();
        await this.sendFile8003();
        this.viewSendProcess8011();
        await this.sendFile8327();
        this.viewSendProcess8328();
        //
        let delFlag = this.data.Recodes.deleteFlag;
        delete this.data.Recodes.deleteFlag;
        const save = await api.SendReport(this.data);
        if (save == false || save.result == "Fail") {
          alert("登録失敗[Error0830-1]");
          this.isLoading = false;
          return;
        }

        if (this.sendCopyFlag) {
          this.sendCopy();
          return;
        }
        alert("登録完了");
        if (delFlag) {
          this.deleteModal = true;
          await this.del();
          return;
        }
        if (!this.noBack) {
          this.back();
        } else {
          this.$set(this.$route.params, "report_id", save.reportID);
          this.$set(this.data, "ReportID", save.reportID);
          await this.getInit();
          this.isLoading = false;
          this.noBack = false;
        }
      }
    },
    back() {
      store.commit("clearreports");
      if (this.copyFlag) {
        history.go(this.backCount);
      } else {
        history.back();
      }
    },
    async del() {
      this.isLoading = true;
      await api.DelReport(this.data);
      alert("削除完了");
      this.back();
    },
    async changeRole() {
      this.data.Recodes.FID8494.subReports.forEach(subReport => {
        if (subReport.Recodes.FID8542.value == "グループリーダー" && 
          subReport.Recodes.FID8496.value.includes('申請中'))
        {
          subReport.Recodes.FID8542.value = "グループマネージャー";
        }
      });
      await this.send();
    },
    async getAllDepartmentsForAdmin() {
      [this.allDivisinos, this.allDepartments,  this.selectBoxFID7777] =
            await Promise.all([
              getDivisionAll(),
              getDepartmentAll(),
              eneosCar.getPositionAll()
            ]);
          
      const devision_values = this.allDivisinos.map((division) => {
        return division.value
      });
      // 管理本部を除外
      this.allDepartments = this.allDepartments.filter((department) => { 
        let devison_flag = devision_values.some((devision_value) => {
          return department.value == devision_value
        })

        return !devison_flag 
      });
    }
  },
};
</script>
<style></style>
