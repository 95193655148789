<template>
  <div class="body">
    <p align="center" class="title">マイカー承認一覧</p>
    <v-row>
      <v-col align="right" class="button">
        <v-btn @click.once="back">戻る</v-btn>
      </v-col>
    </v-row>
    <div v-if="isLoading" align="center">
      <v-progress-circular
        :size="250"
        color="primary"
        indeterminate
        align="center"
      ></v-progress-circular>
    </div>
    <div v-else>
      <v-form ref="form" v-model="valid">
        <v-row class="ml-2 mr-1">
          <v-col cols="0">
            <v-text-field
              label="(承認部署)"
              v-model="keyword8590"
              background-color="white"
              id="FID8590"
              class="searchForm"
              outlined
              clearable
              hide-details="auto"
            >
            </v-text-field> </v-col
          ><v-col cols="0">
            <v-text-field
              label="所属"
              v-model="keyword8482"
              background-color="white"
              id="FID8482"
              class="searchForm"
              outlined
              clearable
              hide-details="auto"
            >
            </v-text-field> </v-col
          ><v-col cols="0">
            <v-text-field
              label="氏名"
              v-model="keyword8483"
              background-color="white"
              id="FID8483"
              class="searchForm"
              outlined
              clearable
              hide-details="auto"
            >
            </v-text-field> </v-col
          ><v-col cols="0"
            ><v-btn x-large @click="search()" color="primary white--text"
              >検索</v-btn
            ></v-col
          >
        </v-row>
        <v-row class="ml-2 mr-1"> </v-row>
        <v-row class="ml-2 mr-1">
          <v-col cols="">
            <v-data-table
              class="reportIndex"
              :headers="headers"
              :items="reports"
              :item-key="reports.ID"
              :page="
                $store.state.pageNum == undefined ? 1 : $store.state.pageNum
              "
              @update:page="updatePageNum"
              :sort-by="sortBy"
              :sort-desc="sortDesc"
              @click:row="move"
              :header-props="{
                'sort-by-text': '並び順',
              }"
              :footer-props="{
                'items-per-page-text': '行/ページ:',
              }"
              no-data-text="データが存在しません"
              :items-per-page="displayResults"
              @update:sort-by="updateSortBy"
              @update:sort-desc="updateSortDesc"
              :custom-sort="customSort"
            >
              <template v-slot:item.Recodes.FID8541.value="{ item }">{{
                item.Recodes.FID8494.subReports.length > 0
                  ? item.Recodes.FID8494.subReports[
                      item.Recodes.FID8494.subReports.length - 1
                    ].Recodes.FID8541.value
                  : ""
              }}</template
              ><template v-slot:item.Recodes.FID8542.value="{ item }">{{
                item.Recodes.FID8494.subReports.length > 0
                  ? item.Recodes.FID8494.subReports[
                      item.Recodes.FID8494.subReports.length - 1
                    ].Recodes.FID8542.value
                  : ""
              }}</template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </div>
</template>

<script>
import store from "../store/vuex.js";
import { RepositoryFactory } from "../api/RepositoryFactory";
const api = RepositoryFactory.get("seisei_back");
import { searchApprovalMyCar } from "./specialMethod";

export default {
  name: "ReportIndex",
  components: {},
  data() {
    return {
      valid: true,
      initEscape: false,
      isLoading: false,
      fullPage: false,
      displayResults: 15, //5,10,15のいずれか
      height: 100,
      width: 250,
      path: "",
      sortBy: null,
      sortDesc: null,
      //
      PageID: 1156,
      reports: [],
      rules: {
        required: (value) => !!value || "必須項目です",
        requiredArray: (value) => (value.length > 0 ? true : "必須項目です"),
        fullWidth: (value) => {
          return /^[^ -~｡-ﾟ]*$/.test(value) || "全角のみ入力可能です";
        },
      },
      keyword8590: "",
      keyword8482: "",
      keyword8483: "",
    };
  },
  async created() {
    await this.getInit();
  },
  methods: {
    async getInit() {
      //タイトルをデフォルトに戻す
      if (typeof process.env.VUE_APP_BASE_TITLE != "undefined") {
        document.title = process.env.VUE_APP_BASE_TITLE;
      }
      //初期処理
      this.isLoading = true;
      this.getKeyWord(this.$store.state.keyWord);
      this.path = this.$route.path;
      //データ取得
      if (JSON.stringify(this.$store.state.reports) != "{}") {
        this.reports = this.$store.state.reports;
      } else {
        let profile;
        [, profile] = await Promise.all([this.search(), api.GetRole()]);
        store.commit("setRole", profile.Role);
      }
      //
      if (this.$store.state.tableSort.sortBy != null) {
        this.sortBy = this.$store.state.tableSort.sortBy;
        this.sortDesc = this.$store.state.tableSort.sortDesc;
      }
      this.reports = this.reports.reverse();
      if (Object.keys(this.$store.state.organization).length < 2) {
        await store.commit("setDepartmentAll");
      }
      this.isLoading = false;
    },
    getKeyWord(data) {
      if (data.PageID == this.PageID) {
        data.search.forEach((data) => {
          if (data.form_id == 7992) {
            this.keyword8590 = data.value;
          }
          if (data.form_id == 7992) {
            this.keyword8482 = data.value;
          }
          if (data.form_id == 7990) {
            this.keyword8483 = data.value;
          }
        });
      }
    },
    getSearchWord() {
      let data = {
        PageID: this.PageID,
        orderBy: "",
        search: [],
      };

      if (this.keyword8590 != "") {
        data.search.push({ form_id: 7992, value: this.keyword8590 });
      }
      if (this.keyword8482 != "") {
        data.search.push({ form_id: 7992, value: this.keyword8482 });
      }
      if (this.keyword8483 != "") {
        data.search.push({ form_id: 7990, value: this.keyword8483 });
      }

      return data;
    },
    async search() {
      if (this.initEscape == false) {
        this.initEscape = true;
        this.isLoading = false;
        await this.$nextTick();
      }
      const ck = await this.checkValidation();
      if (!ck && !this.isLoading) {
        return;
      }
      //初期処理
      this.isLoading = true;
      //データ追加
      let data = await this.getSearchWord();
      //api
      const reports = await searchApprovalMyCar(data);
      //SearchTag
      if (reports != null) {
        this.reports = reports.reduce((acc, curr) => {
          const existingItem = acc.find(
            (item) => item.ReportID === curr.ReportID
          );
          if (!existingItem) {
            acc.push(curr);
          }
          return acc;
        }, []);
        // 
        store.commit("setreports", reports);
      }
      store.commit("setkeyWord", data);
      this.isLoading = false;
    },

    checkValidation: async function () {
      let form = this.$refs.form;
      let check = form.validate();
      if (!check) {
        const firstError = form.inputs.find((input) => input.hasError);
        this.$vuetify.goTo(firstError, { offset: 100 });
        return check;
      }

      return check;
    },
    create() {
      this.isLoading = true;
      this.$router.push({ path: "/mycar/create" });

      this.isLoading = false;
    },
    move(data) {
      this.isLoading = true;
      this.$router.push({ path: "/mycar/create/" + data.ReportID });

      this.isLoading = false;
    },
    back() {
      store.commit("clearkeyWord");
      store.commit("clearreports");
      store.commit("clearPageNum");
      store.commit("clearTableSort");
      store.commit("clearkeyWord");

      //
      if (this.$route.path == this.path) {
        history.back();
      } else {
        alert("読み込みエラー:ホームに戻ります");
        this.$router.push("/", () => {});
      }
      //history.back();
    },
    getValue(v) {
      if (typeof v == "undefined") {
        return "";
      }
      return v.value;
    },
    downloadCSV(csv, filename) {
      //CSV出力部分
      let blob = new Blob([csv], { type: "text/csv" });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    },
    updatePageNum(value) {
      store.commit("setPageNum", value);
    },
    updateSortBy(value) {
      store.commit("setTableSortBy", value);
    },
    updateSortDesc(value) {
      store.commit("setTableSortDesc", value);
    },
    customSort(items, index, isDesc) {
      //デフォルト
      if (index.length == 0 || items.length == 0) {
        return items;
      }
      const regex = /[^0-9]/g;
      const order = isDesc[0] ? 1 : -1;
      const pos = parseInt(index[0].replace(regex, ""));
      items.sort((a, b) => {
        //アイコンソートは特殊処理
        if (index == "target") {
          // //アイコンの特殊ソート処理は実装途中
          return 0;
        }
        //
        return this.sort(a, b, order, pos);
      });
      //
      return items;
    },
    sort(a, b, order, pos) {
      if (
        typeof a.Recodes["FID" + pos] == "undefined" ||
        typeof b.Recodes["FID" + pos] == "undefined"
      )
        return -1;
      if (a.Recodes["FID" + pos].value == b.Recodes["FID" + pos].value)
        return 0;
      if (a.Recodes["FID" + pos].value === "") return 1;
      if (b.Recodes["FID" + pos].value === "") return -1;
      if (
        !isNaN(a.Recodes["FID" + pos].value) &&
        !isNaN(b.Recodes["FID" + pos].value)
      ) {
        return Number(a.Recodes["FID" + pos].value) ==
          Number(b.Recodes["FID" + pos].value)
          ? 0
          : Number(a.Recodes["FID" + pos].value) >
            Number(b.Recodes["FID" + pos].value)
          ? -1 * order
          : order;
      }
      return (
        a.Recodes["FID" + pos].value.localeCompare(
          b.Recodes["FID" + pos].value,
          "jp"
        ) *
        -1 *
        order
      );
    },
    dateSort(dateArr) {
      const date1 = new Date(dateArr[0].replace(/-/g, "/"));
      const date2 = new Date(dateArr[1].replace(/-/g, "/"));
      if (date2 - date1 > 0) {
        return dateArr;
      } else {
        return [dateArr[1], dateArr[0]];
      }
    },
  },
  computed: {
    headers() {
      return [
        {
          text: "社員コード",
          value: "Recodes.FID7989.value",
          align: "start",
          sortable: true,
        },
        { text: "ステータス", value: "Recodes.FID8504.value", sortable: true },
        { text: "氏名", value: "Recodes.FID7990.value", sortable: true },
        {
          text: "四輪車・バイク",
          value: "Recodes.FID8269.value",
          sortable: true,
        },
        { text: "使用用途", value: "Recodes.FID7995.value", sortable: true },
        { text: "車両番号", value: "Recodes.FID7996.value", sortable: true },
        { text: "備考欄", value: "Recodes.FID8334.value", sortable: true },
        {
          text: "承認グループ",
          value: "Recodes.FID8541.value",
          sortable: true,
        },
        { text: "承認役職", value: "Recodes.FID8542.value", sortable: true },
      ];
    },
  },
};
</script>
