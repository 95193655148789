<template>
  <div class="body">
    <p align="center" class="title">マイカー添付書類確認</p>
    <v-row>
      <v-col align="right" class="button">
        <v-btn
          @click="makeCSVDownload"
          class="mr-5"
          color="orange white--text"
          :disabled="isLoading"
        >
          CSV出力
        </v-btn>
        <v-btn @click.once="back">戻る</v-btn>
      </v-col>
    </v-row>
    <div v-if="isLoading" align="center">
      <v-progress-circular
        :size="250"
        color="primary"
        indeterminate
        align="center"
      ></v-progress-circular>
    </div>
    <div v-else>
      <v-form ref="form" v-model="valid">
        <v-row class="ml-2 mr-1">
          <v-col cols="6" sm="6" md="2" lg="2">
            <v-text-field
              label="事業本部"
              v-model="keyword10938"
              background-color="white"
              id="FID10938"
              class="searchForm"
              outlined
              clearable
              hide-details="auto"
              disabled
            >
            </v-text-field>
          </v-col>
          <v-col cols="6" sm="6" md="2" lg="2">
            <v-text-field
              label="部署"
              v-model="keyword2"
              background-color="white"
              outlined
              clearable
              hide-details="auto"
              disabled
            >
            </v-text-field>
          </v-col>
          <!--
          <v-col cols="6" sm="6" md="3" lg="3" >
            <v-autocomplete
              label="部署"
              v-model="keyword10939"
              background-color="white"
              id="FID10939"
              class="searchForm selectBox10939"
              no-data-text="データが見つかりません"
              :items="selectBoxFID10939"
              outlined
              clearable
              hide-details="auto"
            >
            </v-autocomplete>
          </v-col>
          -->
          <v-col cols="6" sm="6" md="3" lg="3">
            <v-autocomplete
              label="グループ"
              :rules="[]"
              v-model="keyword3"
              :items="selectBox3"
              id="FID8322"
              class="selectBox8322"
              outlined
              no-data-text="データが見つかりません"
              clearable
              item-text="label"
              item-value="value"
              hide-details="auto"
              @change="setSelectBox4"
              :disabled="disabledSelectbox_3_4"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="6" sm="6" md="3" lg="3">
            <v-autocomplete
              label="店舗"
              v-model="keyword4"
              :items="selectBox4"
              id="FID8322"
              class="selectBox8322"
              outlined
              no-data-text="データが見つかりません"
              clearable
              item-text="label"
              item-value="value"
              hide-details="auto"
              :disabled="disabledSelectbox_3_4"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="0" sm="0" md="0" lg="0"> </v-col>
          <v-col cols="6" sm="6" md="4" lg="4">
            <v-menu
              v-model="keyword10940.show"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on }"
                ><v-text-field
                  label="運転免許証有効期限"
                  v-model="keyword10940.date"
                  background-color="white"
                  id="FID10940"
                  class="searchForm"
                  outlined
                  v-on="on"
                  readonly
                  clearable
                  hide-details="auto"
                >
                </v-text-field> </template
              ><v-date-picker
                v-model="keyword10940.date"
                no-title
                scrollable
                locale="ja-jp"
                :day-format="(date) => new Date(date).getDate()"
                range
                @input="
                  keyword10940.show =
                    keyword10940.date.length > 1 ? false : true
                "
              ></v-date-picker>
            </v-menu> </v-col
          ><v-col cols="0"
            ><v-btn x-large @click="search()" color="primary white--text"
              >検索</v-btn
            ></v-col
          ><v-col cols="0">
            <v-checkbox
              v-model="isShowAlert"
              label="アラートがあるデータのみ表示"
              class="left"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row class="ml-2 mr-1"> </v-row>
        <v-row class="ml-2 mr-1">
          <v-col cols="">
            <v-data-table
              class="reportIndex"
              :headers="headers"
              :items="filteredReports"
              :item-key="reports.ID"
              :page="
                $store.state.pageNum == undefined ? 1 : $store.state.pageNum
              "
              @update:page="updatePageNum"
              :sort-by="sortBy"
              :sort-desc="sortDesc"
              @click:row="move"
              :header-props="{
                'sort-by-text': '並び順',
              }"
              :footer-props="{
                'items-per-page-text': '行/ページ:',
              }"
              no-data-text="データが存在しません"
              :items-per-page="displayResults"
              @update:sort-by="updateSortBy"
              @update:sort-desc="updateSortDesc"
              :custom-sort="customSort"
            >
              <template v-slot:item.Target="{ item }">
                <v-chip
                  v-if="getLabel(item) != ''"
                  :color="getIconColor(item)"
                  class="white--text"
                >
                  {{ getLabel(item) }}
                </v-chip>
              </template>
              <template v-slot:item.Recodes.FID7991.value="{ item }">
                {{
                  typeof item.Recodes.FID7991 == "undfined"
                    ? ""
                    : $store.state.organization[item.Recodes.FID7991.value]
                }}
              </template>
              <template v-slot:item.Recodes.FID7992.value="{ item }">
                {{
                  typeof item.Recodes.FID7992 == "undfined"
                    ? ""
                    : $store.state.organization[item.Recodes.FID7992.value]
                }}
              </template>
              <template v-slot:item.Recodes.FID8323.value="{ item }">
                <td :class="itemRowBackground(item.Recodes.FID8323.value)">{{item.Recodes.FID8323.value}}</td>
              </template>
              <template v-slot:item.Recodes.FID8324.value="{ item }">
                <td :class="itemRowBackground(item.Recodes.FID8324.value)">{{item.Recodes.FID8324.value}}</td>
              </template>
              <template v-slot:item.Recodes.FID8325.value="{ item }">
                <td :class="itemRowBackground(item.Recodes.FID8325.value)">{{item.Recodes.FID8325.value}}</td>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </div>
</template>

<script>
import store from "../store/vuex.js";
import { RepositoryFactory } from "../api/RepositoryFactory";
const api = RepositoryFactory.get("seisei_back");
const eneosCar = RepositoryFactory.get("eneosCar");
import { MyCar } from "./csvExport/myCar";
import { shopManager } from "./specialMethod";
export default {
  name: "ReportIndex",
  components: {},
  data() {
    return {
      valid: true,
      initEscape: false,
      isLoading: false,
      fullPage: false,
      displayResults: 15, //5,10,15のいずれか
      height: 100,
      width: 250,
      path: "",
      sortBy: null,
      sortDesc: null,
      //
      PageID: 1156,
      reports: [],
      rules: {
        required: (value) => !!value || "必須項目です",
        requiredArray: (value) => (value.length > 0 ? true : "必須項目です"),
        fullWidth: (value) => {
          return /^[^ -~｡-ﾟ]*$/.test(value) || "全角のみ入力可能です";
        },
      },
      keyword10938: "",
      keyword10938Code: "",
      //selectBoxFID10939: [],
      //keyword10939: "",
      keyword2: "", //検索項目にはないが必要
      keyword2Code: "", //検索項目にはないが必要
      keyword3: "",
      keyword4: "",
      ALLDepartment: "",
      ALLShop: "",
      selectBox3: [],
      selectBox4: [],
      keyword10940: {
        date: [
          "",
          "",
          /*
          new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            1,
            new Date().getHours() + 9
          )
            .toISOString()
            .match(/^\d{4}-\d{2}-\d{2}/g)
            .pop(),
          new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date(
              new Date().getFullYear(),
              new Date().getMonth() + 1,
              0
            ).getDate(),
            new Date().getHours() + 9
          )
            .toISOString()
            .match(/^\d{4}-\d{2}-\d{2}/g)
            .pop(),
            */
        ],
        show: false,
      },
      isShowAlert: false,
    };
  },
  async created() {
    await this.getInit();
  },
  methods: {
    async getInit() {
      //タイトルをデフォルトに戻す
      if (typeof process.env.VUE_APP_BASE_TITLE != "undefined") {
        document.title = process.env.VUE_APP_BASE_TITLE;
      }
      //初期処理
      this.isLoading = true;
      this.getKeyWord(this.$store.state.keyWord);
      this.path = this.$route.path;
      //初期値の設定
      const res1 = await eneosCar.getDivision(store.state.params.FID7767.value);
      this.keyword10938 = res1[0].label;
      this.keyword10938Code = res1[0].value;
      //
      if (Object.keys(this.$store.state.organization).length < 2) {
        await store.commit("setDepartmentAll");
      }
      //データ取得
      const [orgRes] = await Promise.all([
        eneosCar.getOrganizationChart(),
        store.commit("setDepartmentAll"),
      ]);
      //選択肢の設定
      this.initKeywords(orgRes);
      //
      await this.search();
      //
      if (this.$store.state.tableSort.sortBy != null) {
        this.sortBy = this.$store.state.tableSort.sortBy;
        this.sortDesc = this.$store.state.tableSort.sortDesc;
      }
      //this.reports = this.reports.reverse();
      this.isLoading = false;
    },
    getKeyWord(data) {
      if (data.PageID == this.PageID) {
        data.search.forEach((data) => {
          if (data.form_id == 7991) {
            this.keyword10938 = data.value;
          }
          // if (data.form_id == 7992) {
          //   this.keyword10939 = data.value;
          // }
          if (data.form_id == 8323) {
            this.keyword10940.date = data.value.split(",");
          }
        });
      }
    },
    getSearchWord() {
      let data = {
        PageID: this.PageID,
        orderBy: "",
        search: [],
      };

      if (this.keyword10938 != "") {
        data.search.push({
          form_id: 7991, //事業本部
          value: this.keyword10938Code,
          option: "match",
        });
      }
      /*
      if (this.keyword10939 != "") {
        data.search.push({
          form_id: 7992,
          value: this.keyword10939,
          option: "match",
        });
      }
      */
      //車両情報の取得
      if (this.keyword4 != "" && this.keyword4 != null) {
        data.search.push({
          form_id: 7992,
          value: this.keyword4,
          option: "match",
        });
      } else if (this.keyword3 != "" && this.keyword3 != null) {
        this.getAllShopString()
        data.search.push({
          form_id: 7992,
          value: this.ALLShop,
          option: "commaValuesAnyMatch",
        });
      } else if (!shopManager()) {
        data.search.push({
          form_id: 7992,
          value: this.ALLDepartment,
          option: "commaValuesAnyMatch",
        });
      }
      if (this.keyword10940.date != null && this.keyword10940.date.length > 0) {
        let date = this.keyword10940.date[0];
        if (this.keyword10940.date.length > 1) {
          this.keyword10940.date = this.dateSort(this.keyword10940.date);
          date = this.keyword10940.date.join(",");
        }
        data.search.push({ form_id: 8323, value: date, option: "dateOnly" });
      }
      this.keyword10940.show = false;

      return data;
    },
    async search() {
      if (this.initEscape == false) {
        this.initEscape = true;
        this.isLoading = false;
        await this.$nextTick();
      }
      const ck = await this.checkValidation();
      if (!ck && !this.isLoading) {
        return;
      }
      //初期処理
      this.isLoading = true;
      //データ追加
      let data = await this.getSearchWord();
      //api
      const [reports] = await Promise.all([api.ReportSearch(data)]);
      //レポートの取得
      if (reports != null) {
        this.reports = reports;
        store.commit("setreports", reports);
      }
      //
      store.commit("setkeyWord", data);
      this.isLoading = false;
    },
    checkValidation: async function () {
      let form = this.$refs.form;
      let check = form.validate();
      if (!check) {
        const firstError = form.inputs.find((input) => input.hasError);
        this.$vuetify.goTo(firstError, { offset: 100 });
        return check;
      }

      return check;
    },
    create() {
      this.isLoading = true;
      this.$router.push({ path: "/mycar/create" });

      this.isLoading = false;
    },
    move(data) {
      this.isLoading = true;
      this.$router.push({ path: "/mycar/create/" + data.ReportID });

      this.isLoading = false;
    },
    back() {
      store.commit("clearkeyWord");
      store.commit("clearreports");
      store.commit("clearPageNum");
      store.commit("clearTableSort");
      store.commit("clearkeyWord");

      //
      if (this.$route.path == this.path) {
        history.back();
      } else {
        alert("読み込みエラー:ホームに戻ります");
        this.$router.push("/", () => {});
      }
      //history.back();
    },
    getValue(v) {
      if (typeof v == "undefined") {
        return "";
      }
      return v.value;
    },
    getLabel(report) {
      if (
        typeof report.Recodes.FID8504 != "undefined" &&
        (report.Recodes.FID8504.value == "" ||
          report.Recodes.FID8504.value == null)
      ) {
        return "";
      }
      if (
        typeof report.Recodes.FID8504 != "undefined" &&
        report.Recodes.FID8504.value == "申請前"
      ) {
        return "一時保存";
      }
      if (
        typeof report.Recodes.FID8504 != "undefined" &&
        report.Recodes.FID8504.value == "承認済"
      ) {
        return "承認済";
      }
      if (
        typeof report.Recodes.FID8504 != "undefined" &&
        report.Recodes.FID8504.value == "棄却"
      ) {
        return "棄却";
      }
      if (
        typeof report.Recodes.FID8504 != "undefined" &&
        report.Recodes.FID8504.value != "" &&
        report.Recodes.FID8504.value != null
      ) {
        return "申請中";
      }
      return "";
    },
    getIconColor(report) {
      if (
        typeof report.Recodes.FID8504 != "undefined" &&
        (report.Recodes.FID8504.value == "" ||
          report.Recodes.FID8504.value == null)
      ) {
        return "white";
      }
      if (
        typeof report.Recodes.FID8504 != "undefined" &&
        report.Recodes.FID8504.value == "申請前"
      ) {
        return "pink";
      }
      if (
        typeof report.Recodes.FID8504 != "undefined" &&
        report.Recodes.FID8504.value == "承認済"
      ) {
        return "grey";
      }
      if (
        typeof report.Recodes.FID8504 != "undefined" &&
        report.Recodes.FID8504.value == "棄却"
      ) {
        return "blue lighten-4";
      }
      if (
        typeof report.Recodes.FID8504 != "undefined" &&
        report.Recodes.FID8504.value != "" &&
        report.Recodes.FID8504.value != null
      ) {
        return "primary";
      }
      return "";
    },
    downloadCSV(csv, filename) {
      //CSV出力部分
      let blob = new Blob([csv], { type: "text/csv" });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    },
    updatePageNum(value) {
      store.commit("setPageNum", value);
    },
    updateSortBy(value) {
      store.commit("setTableSortBy", value);
    },
    updateSortDesc(value) {
      store.commit("setTableSortDesc", value);
    },
    customSort(items, index, isDesc) {
      //デフォルト
      if (index.length == 0 || items.length == 0) {
        return items;
      }
      const regex = /[^0-9]/g;
      const order = isDesc[0] ? 1 : -1;
      const pos = parseInt(index[0].replace(regex, ""));
      items.sort((a, b) => {
        //アイコンソートは特殊処理
        if (index == "target") {
          // //アイコンの特殊ソート処理は実装途中
          return 0;
        }
        //
        return this.sort(a, b, order, pos);
      });
      //
      return items;
    },
    sort(a, b, order, pos) {
      if (
        typeof a.Recodes["FID" + pos] == "undefined" ||
        typeof b.Recodes["FID" + pos] == "undefined"
      )
        return -1;
      if (a.Recodes["FID" + pos].value == b.Recodes["FID" + pos].value)
        return 0;
      if (a.Recodes["FID" + pos].value === "") return 1;
      if (b.Recodes["FID" + pos].value === "") return -1;
      if (
        !isNaN(a.Recodes["FID" + pos].value) &&
        !isNaN(b.Recodes["FID" + pos].value)
      ) {
        return Number(a.Recodes["FID" + pos].value) ==
          Number(b.Recodes["FID" + pos].value)
          ? 0
          : Number(a.Recodes["FID" + pos].value) >
            Number(b.Recodes["FID" + pos].value)
          ? -1 * order
          : order;
      }
      return (
        a.Recodes["FID" + pos].value.localeCompare(
          b.Recodes["FID" + pos].value,
          "jp"
        ) *
        -1 *
        order
      );
    },
    dateSort(dateArr) {
      const date1 = new Date(dateArr[0].replace(/-/g, "/"));
      const date2 = new Date(dateArr[1].replace(/-/g, "/"));
      if (date2 - date1 > 0) {
        return dateArr;
      } else {
        return [dateArr[1], dateArr[0]];
      }
    },
    initKeywords(response) {
      this.keyword2 = response.label;
      this.keyword2Code = response.value;
      this.ALLDepartment += response.value + ",";
      this.selectBox3 = response.group;

      // 部署配下全検索用のリストをALLDepartmentに入れ込む
      for (let i = 0; i < response.group.length; i++) {
        if (response.group[i].value == store.state.params.FID7776.value) {
          this.keyword3 = response.group[i].value;
          if (response.group[i].shop != null) {
            this.selectBox4 = response.group[i].shop;
          }
        }
        if (response.group[i].shop != null) {
          let find_shop_flag = false;
          let group_shops =  response.group[i].shop
          for (let j = 0; j < group_shops.length; j++) {
            if (group_shops[j].value == store.state.params.FID7776.value) {
              this.keyword3 = response.group[i].value;
              this.keyword4 = group_shops[j].value;
              find_shop_flag = true
            }
            this.ALLDepartment += group_shops[j].value + ",";
          }
          if (find_shop_flag) this.selectBox4 = group_shops;
        }
        this.ALLDepartment += response.group[i].value + ",";
      }
      //末尾のカンマを削除
      this.ALLDepartment = this.ALLDepartment.slice(0, -1);
    },
    setSelectBox4() {
      //対象を探す
      if (this.keyword3 == null) {
        this.selectBox4 = [];
        this.keyword4 = "";
        return;
      }
      for (let i = 0; i < this.selectBox3.length; i++) {
        if (this.selectBox3[i].value == this.keyword3) {
          this.selectBox4 = this.selectBox3[i].shop;
          this.keyword4 = "";
          break;
        }
      }
    },
    async makeCSVDownload() {
      let name = "マイカー添付書類確認";
      await store.commit("setDepartmentAll");
      MyCar(this.reports, name);
    },
    getAllShopString() {
      // 選択されたグループとその配下の店舗のリストを取得
      let group_string = this.keyword3 + ','
      let shop_string = "";
      if (this.selectBox4 != null) {
        shop_string = this.selectBox4.map(function(v) {
          return v.value;
        }).join(",");
      }
      this.ALLShop = group_string + shop_string
    },
    // 有効期限3か月前よりアラート表示をする
    itemRowBackground(dateValue) {
      // 各期限日
      let targetDate =  new Date(dateValue)
      // 今日の3か月後を取得
      let date3MonthAfterFromNow = new Date().setMonth(new Date().getMonth() + 3);
      // 今日
      let now = new Date();
      
      if (now > targetDate) {
        // 今日が期限を過ぎているか
        return 'red-alert'
      } else if (targetDate <= date3MonthAfterFromNow) {
        // 対象の日が今日より３か月後以内かどうか
        return 'yellow-alert'
      }
      return ""
    },
  },
  computed: {
    headers() {
      return [
        {
          text: "ステータス",
          value: "Target",
          align: "start",
          sortable: false,
        },
        {
          text: "事業本部",
          value: "Recodes.FID7991.value",
          align: "start",
          sortable: true,
        },
        { text: "部署", value: "Recodes.FID7992.value", sortable: true },
        { text: "車両番号", value: "Recodes.FID7996.value", sortable: true },
        {
          text: "四輪車・バイク",
          value: "Recodes.FID8269.value",
          sortable: true,
        },
        { text: "氏名", value: "Recodes.FID7990.value", sortable: true },
        {
          text: "運転免許証有効期限",
          value: "Recodes.FID8323.value",
          sortable: true,
        },
        { text: "車検満了日", value: "Recodes.FID8324.value", sortable: true },
        {
          text: "自動車保険満了日",
          value: "Recodes.FID8325.value",
          sortable: true,
        },
      ];
    },
    disabledSelectbox_3_4() {
      return shopManager();
    },
    filteredReports() {
      if (this.isShowAlert) {
        return this.reports.filter(report => {
          const bgClass8323 = this.itemRowBackground(report.Recodes.FID8323.value);
          const bgClass8324 = this.itemRowBackground(report.Recodes.FID8324.value);
          const bgClass8325 = this.itemRowBackground(report.Recodes.FID8325.value);
          return (bgClass8323 === 'red-alert' || bgClass8323 === 'yellow-alert') ||
                 (bgClass8324 === 'red-alert' || bgClass8324 === 'yellow-alert') ||
                 (bgClass8325 === 'red-alert' || bgClass8325 === 'yellow-alert');
        });
      }
      return this.reports;
    },
  },
};
</script>
<style>
.red-alert {
  background-color: #FF7D7D !important;
}
.yellow-alert {
  background-color: #FFFF00 !important;
}
</style>