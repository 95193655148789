import store from "../store/vuex.js";
//
export function convertHalfWidthToFullWidthKana(input) {
  const halfWidthKana = [
    { pattern: /ｶﾞ/g, value: "ガ" },
    { pattern: /ｷﾞ/g, value: "ギ" },
    { pattern: /ｸﾞ/g, value: "グ" },
    { pattern: /ｹﾞ/g, value: "ゲ" },
    { pattern: /ｺﾞ/g, value: "ゴ" },
    { pattern: /ｻﾞ/g, value: "ザ" },
    { pattern: /ｼﾞ/g, value: "ジ" },
    { pattern: /ｽﾞ/g, value: "ズ" },
    { pattern: /ｾﾞ/g, value: "ゼ" },
    { pattern: /ｿﾞ/g, value: "ゾ" },
    { pattern: /ﾀﾞ/g, value: "ダ" },
    { pattern: /ﾁﾞ/g, value: "ヂ" },
    { pattern: /ﾂﾞ/g, value: "ヅ" },
    { pattern: /ﾃﾞ/g, value: "デ" },
    { pattern: /ﾄﾞ/g, value: "ド" },
    { pattern: /ﾊﾞ/g, value: "バ" },
    { pattern: /ﾋﾞ/g, value: "ビ" },
    { pattern: /ﾌﾞ/g, value: "ブ" },
    { pattern: /ﾍﾞ/g, value: "ベ" },
    { pattern: /ﾎﾞ/g, value: "ボ" },
    { pattern: /ﾊﾟ/g, value: "パ" },
    { pattern: /ﾋﾟ/g, value: "ピ" },
    { pattern: /ﾌﾟ/g, value: "プ" },
    { pattern: /ﾍﾟ/g, value: "ペ" },
    { pattern: /ﾎﾟ/g, value: "ポ" },
    { pattern: /ｱ/g, value: "ア" },
    { pattern: /ｲ/g, value: "イ" },
    { pattern: /ｳ/g, value: "ウ" },
    { pattern: /ｴ/g, value: "エ" },
    { pattern: /ｵ/g, value: "オ" },
    { pattern: /ｶ/g, value: "カ" },
    { pattern: /ｷ/g, value: "キ" },
    { pattern: /ｸ/g, value: "ク" },
    { pattern: /ｹ/g, value: "ケ" },
    { pattern: /ｺ/g, value: "コ" },
    { pattern: /ｻ/g, value: "サ" },
    { pattern: /ｼ/g, value: "シ" },
    { pattern: /ｽ/g, value: "ス" },
    { pattern: /ｾ/g, value: "セ" },
    { pattern: /ｿ/g, value: "ソ" },
    { pattern: /ﾀ/g, value: "タ" },
    { pattern: /ﾁ/g, value: "チ" },
    { pattern: /ﾂ/g, value: "ツ" },
    { pattern: /ﾃ/g, value: "テ" },
    { pattern: /ﾄ/g, value: "ト" },
    { pattern: /ﾅ/g, value: "ナ" },
    { pattern: /ﾆ/g, value: "ニ" },
    { pattern: /ﾇ/g, value: "ヌ" },
    { pattern: /ﾈ/g, value: "ネ" },
    { pattern: /ﾉ/g, value: "ノ" },
    { pattern: /ﾊ/g, value: "ハ" },
    { pattern: /ﾋ/g, value: "ヒ" },
    { pattern: /ﾌ/g, value: "フ" },
    { pattern: /ﾍ/g, value: "ヘ" },
    { pattern: /ﾎ/g, value: "ホ" },
    { pattern: /ﾏ/g, value: "マ" },
    { pattern: /ﾐ/g, value: "ミ" },
    { pattern: /ﾑ/g, value: "ム" },
    { pattern: /ﾒ/g, value: "メ" },
    { pattern: /ﾓ/g, value: "モ" },
    { pattern: /ﾔ/g, value: "ヤ" },
    { pattern: /ﾕ/g, value: "ユ" },
    { pattern: /ﾖ/g, value: "ヨ" },
    { pattern: /ﾗ/g, value: "ラ" },
    { pattern: /ﾘ/g, value: "リ" },
    { pattern: /ﾙ/g, value: "ル" },
    { pattern: /ﾚ/g, value: "レ" },
    { pattern: /ﾛ/g, value: "ロ" },
    { pattern: /ﾜ/g, value: "ワ" },
    { pattern: /ｦ/g, value: "ヲ" },
    { pattern: /ﾝ/g, value: "ン" },
    { pattern: /ｯ/g, value: "ッ" },
    { pattern: /ｬ/g, value: "ャ" },
    { pattern: /ｭ/g, value: "ュ" },
    { pattern: /ｮ/g, value: "ョ" },
    { pattern: /ｰ/g, value: "ー" },
    { pattern: /-/g, value: "ー" },
    // 他の半角カタカナと対応する全角カタカナを追加
  ];

  let output = input;

  halfWidthKana.forEach((item) => {
    output = output.replace(item.pattern, item.value);
  });

  return output;
}

export function convertExcelDate(excelDate) {
  if (typeof excelDate == "undefined") {
    return "";
  }
  // エクセルの日時は1900年1月1日からの日数で表されています
  var baseDate = new Date("1899-12-31");
  // Excelの日付が60以上の場合、1900年2月29日の存在によるずれを修正
  if (excelDate >= 60) {
    excelDate--;
  }
  // エクセルの日時データをミリ秒単位に変換
  var excelDateInMilliseconds =
    baseDate.getTime() + excelDate * 24 * 60 * 60 * 1000;
  // Dateオブジェクトを作成
  var convertedDate = new Date(excelDateInMilliseconds);
  // YYYY-MM-DD形式に変換
  var year = convertedDate.getFullYear();
  var month = (convertedDate.getMonth() + 1).toString().padStart(2, "0");
  var day = (convertedDate.getDate()).toString().padStart(2, "0");

  var result = year + "-" + month + "-" + day;
  return result;
}

import { RepositoryFactory } from "../api/RepositoryFactory";
const eneosCar = RepositoryFactory.get("eneosCar");
const api = RepositoryFactory.get("seisei_back");

//社内IPか判断
export async function getIPAuth() {
  try {
    if (
      typeof store.state.params == "undefined" ||
      !store.state.params.FID7776
    ) {
      const result = await api.EmployeeDetail();
      store.commit("setParams", result.Recodes);
    }
    const response = await eneosCar.GetIP();
    if (response) {
      return true;
    }
    return false;
  } catch (e) {
    console.log("e", e);
    return false;
  }
}
//-------------------------------------------------------------
//Home画面
export async function myCarApprovalLink() {
  const ip = await eneosCar.GetIP();
  if (typeof store.state.params == "undefined") {
    return true;
  }
  if (
    ip &&
    (store.state.params.FID7764.value == "承認者" ||
      store.state.params.FID7764.value == "途中承認者")
  ) {
    return true;
  }
  return false;
}
//
export async function userTempAddFID7767() {
  const response = await eneosCar.getDivision();
  return response;
}

export async function userTempAddFID7776() {
  const response = await eneosCar.getDivision();
  return response;
}

export async function carInspectionCheck() {
  const ip = await eneosCar.GetIP();
  if (
    ip &&
    (store.state.params.FID8748.value.includes("車両確認者(日別)") ||
      store.state.params.FID7765.value.includes("給油確認者") ||
      store.state.params.FID7766.value.includes("車両確認者"))
  ) {
    return true;
  }
  return false;
}

export async function compCarAdderCheck() {
  const ip = await eneosCar.GetIP();
  if (ip && store.state.params.FID8133.value.includes("車両追加者")) {
    return true;
  }
  return false;
}

//-------------------------------------------------------------
//車両利用申請 前回の走行距離を取得
export async function LastRunValue() {
  let data = {
    PageID: 1160,
    orderBy: "",
    search: [],
  };
  let carNumber = "";
  if (
    typeof localStorage.getItem("kakanaiValueFID7996") != "undefined" &&
    localStorage.getItem("kakanaiValueFID7996") != null
  ) {
    carNumber = localStorage.getItem("kakanaiValueFID7996");
  }
  if (
    typeof localStorage.getItem("kakanaiValueFID8007") != "undefined" &&
    localStorage.getItem("kakanaiValueFID8007") != null
  ) {
    carNumber = localStorage.getItem("kakanaiValueFID8007");
  }
  if (carNumber == "") {
    console.log("carNumberが取得できませんでした");
    return "0";
  }
  let today = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate(),
    new Date().getHours() + 9
  )
    .toISOString()
    .match(/^\d{4}-\d{2}-\d{2}/g)
    .pop();
  try {
    //レンタカーの際は、常に前回距離は0にする
    if (carNumber == "レンタカー") {
      return "";
    }
    //前回走行距離の取得
    data.search.push({ form_id: 8023, value: carNumber, option: "match" });
    data.search.push({
      form_id: 9572,
      value: localStorage.getItem("kakanaiValueFID9572"),
      option: "match",
    });

    data.search.push({
      form_id: 8017,
      value: today,
      option: "beforeLessThandata",
    });
    //ステータスを確認
    const reports = await api.ReportSearch(data);
    const report = reports[reports.length - 1];
    const lastValue =
      report.Recodes.FID8026.subReports[0].Recodes.FID8256.value;
    return lastValue;
  } catch (e) {
    console.log("LastRunValue:error", e);
    return "0";
  }
}

//利用申請の車両承認権限のチェック(可ならtrue)
export function carriyou(data, accountRank) {
  //権限の有無
  if (
    typeof store.state.params.FID8748 == "undefined" ||
    !store.state.params.FID8748.value.includes("車両確認者(日別)")
  ) {
    return false;
  }
  if (accountRank == -1) {
    return false;
  }
  //店舗承認者の場合は、同じ店舗の車両のみ承認可とする
  if (accountRank > 3) {
    if (data.Recodes.FID9572.value == store.state.params.FID7776.value) {
      //所属店舗のみ承認可
      console.log("店舗なので所属店舗のみ承認可");
      return true;
    } else {
      console.log("店舗なので所属店舗以外は不可");
      return false;
    }
  }
  console.log("グループ以上の権限者なので全て可");
  //
  return true;
}

//車両承認権限のチェック
export async function getCarApproval() {
  return store.state.CarApproval;
}

//社内かつシステム管理者
export async function settingLink() {
  try {
    const response = await eneosCar.GetIP();
    if (response === true && store.state.role == 1) {
      return true;
    }
    return false;
  } catch (e) {
    console.log("e", e);
    return false;
  }
}

//社内かつユーザ追加権限者
export async function limitUserAdd() {
  try {
    const response = await eneosCar.GetIP();
    if (
      response === true &&
      store.state.params.FID9441 != "undefined" &&
      store.state.params.FID9441.value != ""
    ) {
      return true;
    }
    return false;
  } catch (e) {
    console.log("e", e);
    return false;
  }
}

export async function getDivisionAll() {
  const ipRes = await eneosCar.GetIP();
  if (!ipRes || store.state.role != 1) {
    console.log("getDivisionAll:権限不正");
    return [];
  }
  const response = await eneosCar.getDivisionAll();
  return response;
}

//[etc]:事業本部の取得
export async function getDivision() {
  try {
    const ipRes = await eneosCar.GetIP();
    if (ipRes && store.state.role == 1) {
      return await eneosCar.getDivisionAll();
    }
    const response = await eneosCar.getDivision();
    return response;
  } catch (e) {
    console.log("e", e);
    return false;
  }
}

//[etc]:部署全件の取得
export async function getDepartmentAll() {
  const ipRes = await eneosCar.GetIP();
  if (!ipRes || store.state.role != 1) {
    return await eneosCar.getSection();
    //return [];
  }
  const response = await eneosCar.getDepartmentAll();
  return response;
}

//[ユーザ情報設定]:事業所に応じた部署・店舗の一覧取得
export async function getDivisionTpDepartmentALL(data) {
  try {
    //事業部に基づいた所属の取得
    let code = "";
    if (typeof data != "undefined" && typeof data == "string") {
      code = data;
    }
    let response = await eneosCar.getDepartmentOnDivision(code);
    return response;
  } catch (e) {
    console.log("e", e);
    return false;
  }
}

//[社有車管理]:管理部門の取得
export async function getCompanyCarSection(code) {
  try {
    if (store.state.role == 1) {
      let response = await eneosCar.getDepartmentAll();
      return response;
    }
    let response = await eneosCar.getSectionCode(code);
    return response;
  } catch (e) {
    console.log("e", e);
    return [];
  }
}

//[社有車管理]:部署の取得
export async function getCompanyCarDepartment() {
  try {
    if (store.state.role == 1) {
      let response = await eneosCar.getDepartmentAll();
      return response;
    }
    let response = await eneosCar.getSection();
    return response;
  } catch (e) {
    console.log("e", e);
    return false;
  }
}

//---各申請-----------------------------------------------
//マイカーの取得
export async function getMyCar() {
  try {
    const data = await eneosCar.getMyCar();
    return data;
  } catch (e) {
    console.log("getMyCar", e);
    return [];
  }
}

//社有車の取得
export async function getCompanyCar() {
  try {
    const data = await eneosCar.getCompanyCar();
    return data;
  } catch (e) {
    console.log("getCompanyCar", e);
    return [];
  }
}

function SetVal(type, val) {
  let recode = { fromType: type, value: val, disabled: false };
  return recode;
}

//業務利用車の取得
export async function getBusinessUseCar() {
  try {
    const reports = await eneosCar.getBusinessUseCar();
    //マイカー形式に社有車を変換
    reports.map((r, index) => {
      if (typeof r.Recodes.FID7996 == "undefined") {
        reports[index].Recodes["FID7996"] = SetVal(1, r.Recodes.FID8007.value); //車両番号
        reports[index].Recodes["FID7992"] = SetVal(1, r.Recodes.FID8322.value); //部署
      }
    });
    //
    return reports;
  } catch (e) {
    console.log("getBusinessUseCar", e);
    return [];
  }
}

//マイカー承認申請一覧
export async function searchApprovalMyCar(data) {
  //1.ステータス
  data.search.push({
    form_id: 8504,
    value: "申請中(1) 申請中(2) 申請中(3)",
    option: "anyMatch",
  });
  //2.部署
  data.search.push({
    form_id: 8541,
    value: store.state.params.FID7776.value,
    option: "subpage:match",
  });
  //3.役職
  data.search.push({
    form_id: 8542,
    value: store.state.params.FID7777.value,
    option: "subpage:match",
  });
  //
  let response = await api.ReportSearch(data);
  // ↓自身が承認する段階でないものは取得しないように変更の為、コメント化
  // let res2 = await eneosCar.getApprovalMyCar(data);
  // if (typeof res2.data != "undefined" && res2.data.length > 0) {
  //   response = response.concat(res2.data);
  // }
  return response;
}

export async function searchCompanyCar(data) {
  return await api.ReportSearch(data);
}

//---マイカー申請関連--------------------------------------

//マイカー書類確認権限
export function myCarDocumentCheck() {
  if (store.state.params.FID8134.value.includes("マイカー添付書類確認者")) {
    return true;
  }
  return false;
}

//(承認記録)申請ボタン 初回
export async function collBtn8004No1(data) {
  //パラメータをセット
  let hisRepo =
    data.Recodes.FID8494.subReports[data.Recodes.FID8494.subReports.length - 1];
  if (data.Recodes.FID11971.value != "") {
    data.Recodes.FID8504.value = "削除申請"; //ステータス
    hisRepo.Recodes.FID8496.value = "削除申請"; //ステータス
  } else if (data.Recodes.FID11970.value != "") {
    data.Recodes.FID8504.value = "修正申請"; //ステータス
    hisRepo.Recodes.FID8496.value = "修正申請"; //ステータス
  } else {
    data.Recodes.FID8504.value = "申請前"; //ステータス
    hisRepo.Recodes.FID8496.value = "申請前"; //ステータス
  }
  hisRepo.Recodes.FID8541.value = store.state.params.FID7776.value; //(初回)自身の承認グループ
  hisRepo.Recodes.FID8542.value = store.state.params.FID7777.value; //(初回)自身の承認役職
  //承認ルート・次権限を取得
  const [code, position, status, route] = await eneosCar.getNextApprover(
    hisRepo
  );
  //
  if (code == "error") {
    if (route.startsWith("承認ルート上にユーザが存在しません")) {
      alert("承認ルート[承認ルート上にユーザが存在しません]");
    } else {
      console.log("承認ルートエラー", route);
      alert("承認ルートエラー");
    }
    return "first error";
  } else {
    console.log("承認ルート取得", code, "|", position, "|", status, "|", route);
  }
  //承認記録の更新
  //申請者の情報をセット
  data.Recodes.FID8504.value = status; //(次)ステータス
  hisRepo.Recodes.FID8496.value = status; //(次)ステータス
  hisRepo.Recodes.FID8541.value = code; //(次)承認グループ
  hisRepo.Recodes.FID8542.value = position; //(次)承認役職
  hisRepo.Recodes.FID8562.value = route; //(次)承認役職
  return "";
}

//(承認記録)承認・棄却ボタン権限
export function MyCarApprovalCheck(data) {
  if (typeof store.state.params.FID7776 == "undefined") {
    return false;
  }
  if (
    data.Recodes.FID8496.value != "申請前" &&
    data.Recodes.FID8496.value != "承認済" &&
    data.Recodes.FID8541.value == store.state.params.FID7776.value &&
    data.Recodes.FID8542.value == store.state.params.FID7777.value
  ) {
    return true;
  }
  return false;
}

//(承認記録)承認・棄却ボタン処理
export async function collBtn8567No1(data, mainRecodes) {
  return await myCarVtn(data, mainRecodes, "棄却");
}
export async function collBtn8568No1(data, mainRecodes) {
  return await myCarVtn(data, mainRecodes, "承認");
}

export async function myCarVtn(data, mainRecodes, tag) {
  if (tag == "承認") {
    //1.ボタン無効化
    //2.承認日時の保存
    //3.履歴に承認者名を保存
    data.Recodes.FID8491.subReports[
      data.Recodes.FID8491.subReports.length - 1
    ].Recodes.FID8498.value = store.state.params.FID7762.value;
    //4.履歴に従業員番号を保存
    data.Recodes.FID8491.subReports[
      data.Recodes.FID8491.subReports.length - 1
    ].Recodes.FID8489.value = store.state.user.attributes.name;
    //5.結果
    data.Recodes.FID8491.subReports[
      data.Recodes.FID8491.subReports.length - 1
    ].Recodes.FID8578.value = tag;
    //6.次の承認権限を取得&値を格納
    const [code, position, status] = await eneosCar.getNextApprover(data);
    data.Recodes.FID8496.value = status; //(次)ステータス
    data.Recodes.FID8541.value = code; //(次)承認グループ
    data.Recodes.FID8542.value = position; //(次)承認役職
    //7.親レポートのステータス更新
    mainRecodes.FID8504.value = status;
    //8.更新・削除時の処理
    if (status == "承認済") {
      console.log("mainRecodes.Recodes.FID11970", mainRecodes);
      if (mainRecodes.FID11970.value != "") {
        //修正
        //ドキュメントの確認済みを全てリセット
        mainRecodes.FID8313.value = "";
        mainRecodes.FID8314.value = "";
        mainRecodes.FID8315.value = "";
        mainRecodes.FID8329.value = "";
        mainRecodes.FID11970.value = "";
      } else if (mainRecodes.FID11971.value != "") {
        //削除
        //削除処理
        console.log("削除処理");
        mainRecodes.deleteFlag = true;
      }
    }
  } else {
    //棄却時
    data.Recodes.FID8491.subReports[
      data.Recodes.FID8491.subReports.length - 1
    ].Recodes.FID8578.value = tag; //(親)結果
    //
    data.Recodes.FID8496.value = "棄却"; //(次)ステータス
    data.Recodes.FID8541.value = ""; //(次)承認グループ
    data.Recodes.FID8542.value = ""; //(次)承認役職
    //4.履歴に従業員番号・名前を保存
    data.Recodes.FID8491.subReports[
      data.Recodes.FID8491.subReports.length - 1
    ].Recodes.FID8498.value = store.state.params.FID7762.value;
    data.Recodes.FID8491.subReports[
      data.Recodes.FID8491.subReports.length - 1
    ].Recodes.FID8489.value = store.state.user.attributes.name;
    //7.親レポートのステータス更新
    mainRecodes.FID8504.value = "棄却";
    mainRecodes.FID8004.value = "";
  }

  return;
}

//月次承認
export function carInspection1() {
  return store.state.params.FID7766.value == "車両確認者";
}

//給油確認者
export function carInspection2() {
  return store.state.params.FID7765.value == "給油確認者";
}
//
export function carInspection3() {
  return store.state.params.FID8748.value == "車両確認者(日別)";
}

export function shopManager() {
  return store.state.params.FID7777.value == "マネージャー";
}


//社有車取り込み
export async function importCompanyCar(baseReport, csv) {
  //
  let organizationObj = {};
  //
  let data = [];
  let mainReport = JSON.parse(JSON.stringify(baseReport));
  let today = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate(),
    new Date().getHours() + 9
  )
    .toISOString()
    .match(/^\d{4}-\d{2}-\d{2}/g)
    .pop();
  //管理部門から管理本部を取得
  let alertFlag = false;
  for (let i = 0; i < csv.length; i++) {
    if (csv[i].length > 26 && csv[i][29] != "") {
      let department = String(csv[i][29]);
      if (
        typeof csv[i][29] != "undefined" &&
        typeof department != "undefined" &&
        !isNaN(department) &&
        department != "" &&
        department != null
      ) {
        await setDepartmentToDivision(department);
      } else if (
        typeof csv[i][29] != "undefined" &&
        typeof department != "undefined"
      ) {
        if (!alertFlag) {
          alert(
            "ヘッダーの9列目[" +
              department +
              "]が社有車の所有部署ではありません"
          );
          alertFlag = true;
        }
      }
    }
  }
  //
  csv.forEach((c, index) => {
    if (c.length > 25 && typeof c[29] != "undefined") {
      //
      if (index > 0) {
        data.push(mainReport);
      }
      let dec = DepartmentToDivision[c[29]];
      setRentalCar(organizationObj, dec.Division, c[29]);
      //
      mainReport = JSON.parse(JSON.stringify(baseReport));
      mainReport.Recodes.FID8099.value = today; //初回登録日
      mainReport.Recodes.FID8100.value = today; //最終更新日
      mainReport.Recodes.FID8103.value = "システム管理者"; //登録者
      mainReport.Recodes.FID8104.value = "0000"; //社員番号
      //部門情報
      mainReport.Recodes.FID8321.value = dec.Division; //管理本部
      mainReport.Recodes.FID9238.value = dec.Section; //管理部門
      setValue(mainReport, c, "FID8322", 29); //部署
      //
      mainReport.Recodes.FID8101.value = "新規増車"; //登録事由
      mainReport.Recodes.FID8271.value = today; //初期日
      ////自動車番号または車両番号
      let carNumber = zenkakuToHankaku(String(c[3]));
      mainReport.Recodes.FID8007.value = carNumber;
      setValue(mainReport, c, "FID8613", 4); //使用区分
      let d11 = String(c[7]).split("・");
      mainReport.Recodes.FID8108.value = d11[0]; //自動車の種別
      mainReport.Recodes.FID8109.value = d11[1]; //種別・用途
      setValue(mainReport, c, "FID8110", 8); //最大積載量
      setValue(mainReport, c, "FID8113", 11); //車台番号
      setValue(mainReport, c, "FID8111", 9); //車名
      setValue(mainReport, c, "FID8112", 10); //車種名
      //初年度登録月
      mainReport.Recodes.FID8318.value = convertToJapaneseEra1(String(c[5]));
      //有効期限の満了する日
      mainReport.Recodes.FID8319.value = convertToJapaneseEra2(String(c[19]));
      console.log("有効期限の満了する日", mainReport.Recodes.FID8319.value);
    }
  });
  data.push(mainReport);
  //レンタカーの作成
  data = data.concat(addRentalCar(organizationObj, baseReport));
  //
  return data;
}

function convertToJapaneseEra1(dateString) {
  var year = parseInt(dateString.substring(0, 4));
  var month = parseInt(dateString.substring(4));

  var era;
  if (year >= 2019) {
    era = "令和";
    year -= 2018;
  } else if (year >= 1989) {
    era = "平成";
    year -= 1988;
  } else if (year >= 1926) {
    era = "昭和";
    year -= 1925;
  } else {
    era = "大正";
    year -= 1911;
  }

  return era + year + "年" + month + "月";
}

function convertToJapaneseEra2(serial) {
  var date = new Date(
    (serial - 1) * 24 * 60 * 60 * 1000 + new Date("1899-12-31").getTime()
  );
  var year = parseInt(date.getFullYear());
  var month = parseInt(date.getMonth() + 1);
  var day = parseInt(date.getDate());
  var era;
  if (year >= 2019) {
    era = "令和";
    year -= 2018;
  } else if (year >= 1989) {
    era = "平成";
    year -= 1988;
  }
  return era + year + "年" + month + "月" + day + "日";
}

function setRentalCar(obj, Division, Department) {
  if (typeof obj[Division] == "undefined") {
    obj[Division] = {};
  }

  if (typeof obj[Division][Department] == "undefined") {
    obj[Division][Department] = "レンタカー";
  }
  return;
}

let DepartmentToDivision = {};
export async function setDepartmentToDivision(department) {
  if (
    typeof DepartmentToDivision[department] == "undefined" &&
    typeof department != "undefined"
  ) {
    DepartmentToDivision[department] = {};
    try {
      const res1 = await eneosCar.getDivision(department);
      const res2 = await eneosCar.getSectionCode(department);
      const division = res1[0].value;
      const section = res2[0].value;
      DepartmentToDivision[department].Division = division;
      DepartmentToDivision[department].Section = section;
    } catch (e) {
      console.log("setDepartmentToDivision error", e);
    }
  }
  return;
}

export function addRentalCar(obj, baseReport) {
  let arr = [];
  //
  let today = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate(),
    new Date().getHours() + 9
  )
    .toISOString()
    .match(/^\d{4}-\d{2}-\d{2}/g)
    .pop();
  Object.keys(obj).forEach(function (Division) {
    Object.keys(obj[Division]).forEach(function (department) {
      let mainReport = SetCar(
        //
        baseReport,
        today,
        DepartmentToDivision[department].Division,
        DepartmentToDivision[department].Section,
        department
      );
      arr.push(mainReport);
    });
  });
  return arr;
}

function getCurrentDate() {
  var today = new Date();
  var year = today.getFullYear();
  var month = today.getMonth() + 1; // 月は0から始まるため、+1する
  var japaneseEra = getJapaneseEra(year);
  return japaneseEra + month + "月";
}
function getJapaneseEra(year) {
  var era;
  if (year >= 2019) {
    era = "令和";
    year -= 2018;
  } else if (year >= 1989) {
    era = "平成";
    year -= 1988;
  } else if (year >= 1926) {
    era = "昭和";
    year -= 1925;
  } else {
    era = "大正";
    year -= 1911;
  }
  return era + year + "年";
}

export function SetCar(baseReport, today, Division, Section, department) {
  let mainReport = JSON.parse(JSON.stringify(baseReport));
  mainReport.Recodes.FID8099.value = today; //初回登録日
  mainReport.Recodes.FID8100.value = today; //最終更新日
  mainReport.Recodes.FID8103.value = "システム管理者"; //登録者
  mainReport.Recodes.FID8104.value = "0002"; //社員番号
  //部門情報
  mainReport.Recodes.FID8321.value = Division; //管理本部
  mainReport.Recodes.FID9238.value = Section; //管理部門
  mainReport.Recodes.FID8322.value = department; //部署
  //
  mainReport.Recodes.FID8101.value = "新規増車"; //登録事由
  mainReport.Recodes.FID8271.value = today; //初期日
  mainReport.Recodes.FID8007.value = "レンタカー";
  mainReport.Recodes.FID8108.value = "普通"; //自動車の種別
  mainReport.Recodes.FID8109.value = "乗物"; //種別・用途
  mainReport.Recodes.FID8613.value = "レンタカー"; //使用区分
  mainReport.Recodes.FID8111.value = "レンタカー"; //車名
  mainReport.Recodes.FID8112.value = "-"; //車種名
  mainReport.Recodes.FID8113.value = "-"; //車台番号
  //
  mainReport.Recodes.FID8318.value = getCurrentDate(); //初年度登録月
  return mainReport;
}

export function zenkakuToHankaku(str) {
  return str.replace(/[０-９]/g, function (match) {
    const zenkakuNumbers = "０１２３４５６７８９";
    const hankakuNumbers = "0123456789";
    const index = zenkakuNumbers.indexOf(match);
    return index !== -1 ? hankakuNumbers.charAt(index) : match;
  });
}

function setValue(report, csv, to, from, tag) {
  if (typeof tag == "undefined") {
    if (typeof csv[from] != "undefined" && csv[from] != "") {
      report.Recodes[to].value = String(csv[from]).trim();
    }
    return;
  } else {
    switch (tag) {
      case "add":
        report.Recodes[to].value += String(csv[from]).trim();
        break;
    }
  }
}
